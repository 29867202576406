import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import TrulyInboxEmailAccountConsent from './trulyinbox-email-account-consent';
import { PropsType } from './types';
import { trulyinboxEmailAccountVerifyShApiKeyRequest } from '../../extra-actions';

const mapStateToProps = (state: RootState) => ({
  syncConsentRequestResponse:
    state.emailAccount.sendTrulyinboxEmailAccountVerifyShApiKeyResponse,
});

const mapDispatchToProps = {
  sendTrulyinboxEmailAccountVerifyShApiKeyRequest: () =>
    trulyinboxEmailAccountVerifyShApiKeyRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & PropsType;

export default connector(TrulyInboxEmailAccountConsent);
