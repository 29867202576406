import React, { useRef } from 'react';
import { Pencil } from '@saleshandy/icons';
import { EmailAccount } from '../../../../../types/email-account';

import SMTPLabel from '../../../utils/smtp-label';
import EmailAccountStatusIcon from '../components/email-account-status-icon';
import { getEmailAccountTypeIcon } from '../utils/get-email-account-type-icon';
import { EmailAccountType } from '../../../../../enums/email-account';
import { OverlayTooltip } from '../../../../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';

type IProps = {
  emailAccount: EmailAccount;
  redirectToGeneralSettings: (emailAccount: EmailAccount) => void;
  onSmtpImapModalShow: (emailAccount: EmailAccount) => void;
};

const SenderNameField: React.FC<IProps> = ({
  emailAccount,
  redirectToGeneralSettings,
  onSmtpImapModalShow,
}) => {
  const tooltipRef = useRef(null);

  const { fromName, fromEmail, type, status, isDefault } = emailAccount;

  return (
    <div className="sender-name-field">
      <div className="sender-name-field--inner">
        <h1
          className="fromName"
          {...accessibleOnClick(() => redirectToGeneralSettings(emailAccount))}
        >
          {fromName}
        </h1>
        {type === EmailAccountType.SmtpImap ? (
          <div className="sender-name-field-smtp-label">
            <SMTPLabel />
          </div>
        ) : (
          <img
            className="type"
            src={getEmailAccountTypeIcon(type)}
            alt="Email Type"
          />
        )}
        {isDefault && <div className="default-email-badge">Default</div>}
        {type === EmailAccountType.SmtpImap && (
          <OverlayTooltip text="Edit Email Settings">
            <div
              className="sender-name-field-modify"
              ref={tooltipRef}
              {...accessibleOnClick(() => {
                if (tooltipRef.current) {
                  tooltipRef.current.blur();
                }

                onSmtpImapModalShow(emailAccount);
              })}
            >
              <Pencil width={16} height={16} />
            </div>
          </OverlayTooltip>
        )}
      </div>
      <div className="sender-name-field--inner">
        <p
          className="fromEmail"
          {...accessibleOnClick(() => redirectToGeneralSettings(emailAccount))}
        >
          {fromEmail}
        </p>
        <EmailAccountStatusIcon status={status} />
      </div>
    </div>
  );
};

export default SenderNameField;
