import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import type { IProps } from './do-not-contact-list-container';
import {
  AddModifyDoNotContactListModalState,
  DoNotContactList as DoNotContactListType,
  DoNotContactListsFilter,
  ModalActions,
} from '../../../../types/do-not-contact-list';

import toaster from '../../../../../../shared/toaster';

import DoNotContactListHeader from './components/do-not-contact-list-header';
import DoNotContactListTable from './components/do-not-contact-list-table';
import DeleteDoNotContactList from '../modals/delete-do-not-contact-list';
import DoNotContactListEmptyUI from './components/do-not-contact-list-empty-ui';

import AddModifyDoNotContactList from '../modals/add-modify-do-not-contact-list';

import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
  SubscriptionPlans,
} from '../../../../../../shared/utils';
import { DoNotContactListsSortBy } from '../../../../enums/do-not-contact-list';
import { SortOrder } from '../../../../../../shared/enums/order';
import { DNCEmptyList, NoDNCListFound } from '../../../../../../shared/svg';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import store from '../../../../../../store';
import HeaderBanner from '../../../header-banner';

const DoNotContactList: React.FC<IProps> = ({
  doNotContactLists,
  doNotContactListsPaginationOptions,

  getDoNotContactListsRequestStatus,
  showLoading,
  hideLoading,
  getDoNotContactListsRequestError,
  sendGetDoNotContactListsRequest,
  resetGetDoNotContactListsRequest,

  createDoNotContactListRequestStatus,
  createDoNotContactListRequestError,
  sendCreateDoNotContactListRequest,
  resetCreateDoNotContactListRequest,
  createdDoNotContactListId,
  resetCreatedDoNotContactListId,

  updateDoNotContactListRequestStatus,
  updateDoNotContactListRequestError,
  sendUpdateDoNotContactListRequest,
  resetUpdateDoNotContactListRequest,

  deleteDoNotContactListRequestStatus,
  deleteDoNotContactListRequestError,
  sendDeleteDoNotContactListRequest,
  resetDeleteDoNotContactListRequest,

  agencyClients,
  sendGetAgencyClientsDropdownRequest,
  agencyConfigRequestStatus,
}) => {
  const history = useHistory();

  const [filters, setFilters] = useState<DoNotContactListsFilter>({
    page: 1,
    limit: 25,
    search: '',
    sortByKey: DoNotContactListsSortBy.UpdatedAt,
    order: SortOrder.DESC,
  });

  const [
    selectedDoNotContactList,
    setSelectedDoNotContactList,
  ] = useState<DoNotContactListType>(null);

  const [
    addModifyDoNotContactListModalState,
    setAddModifyDoNotContactListModalState,
  ] = useState<AddModifyDoNotContactListModalState>({
    show: false,
    action: 'add',
  });

  const [
    deleteDoNotContactListModal,
    setDeleteDoNotContactListModal,
  ] = useState(false);

  const onShowAddModifyDoNotContactListModal = (action: ModalActions) => {
    setAddModifyDoNotContactListModalState({
      show: true,
      action,
    });
  };

  const onHideAddModifyDoNotContactListModal = () => {
    setAddModifyDoNotContactListModalState({
      show: false,
      action: 'add',
    });
    setSelectedDoNotContactList(null);
  };

  const onShowDeleteDoNotContactListModal = () => {
    setDeleteDoNotContactListModal(true);
  };

  const onHideDeleteDoNotContactListModal = () => {
    setDeleteDoNotContactListModal(false);
    setSelectedDoNotContactList(null);
  };

  const onFiltersChange = (
    partialFilters: Partial<DoNotContactListsFilter>,
  ) => {
    const updatedFilters = {
      ...filters,
      ...partialFilters,
    };

    setFilters(updatedFilters);
    sendGetDoNotContactListsRequest(updatedFilters);
  };

  const onAction = (key: string, doNotContactList: DoNotContactListType) => {
    if (key === 'edit') {
      onShowAddModifyDoNotContactListModal('modify');
      setSelectedDoNotContactList(doNotContactList);
      return;
    }
    if (key === 'delete') {
      onShowDeleteDoNotContactListModal();
      setSelectedDoNotContactList(doNotContactList);
    }
  };

  const onDeleteDoNotContactList = () => {
    sendDeleteDoNotContactListRequest(selectedDoNotContactList.id);
  };

  useEffect(() => {
    sendGetDoNotContactListsRequest(filters);
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: getDoNotContactListsRequestStatus,
      onPending: () => {
        showLoading();
      },
      onSuccess: () => {
        hideLoading();
        resetGetDoNotContactListsRequest();
      },
      onFailed: () => {
        resetGetDoNotContactListsRequest();
        executeOnErrorWithErrorCheck({
          error: getDoNotContactListsRequestError,
          onError: () => {
            toaster.error(getDoNotContactListsRequestError?.message);
          },
        });
      },
    });
  }, [getDoNotContactListsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: createDoNotContactListRequestStatus,
      onSuccess: () => {
        toaster.success('List added successfully');
        history.push({
          pathname: `/settings/dnc-list/${createdDoNotContactListId}`,
          state: {
            row: null,
          },
        });
        resetCreateDoNotContactListRequest();
        resetCreatedDoNotContactListId();
      },
      onFailed: () => {
        resetCreateDoNotContactListRequest();
        executeOnErrorWithErrorCheck({
          error: createDoNotContactListRequestError,
          onError: () => {
            toaster.error(createDoNotContactListRequestError?.message);
          },
        });
      },
    });
  }, [createDoNotContactListRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateDoNotContactListRequestStatus,
      onSuccess: () => {
        toaster.success('List modified successfully');
        sendGetDoNotContactListsRequest(filters);
        onHideAddModifyDoNotContactListModal();
        resetUpdateDoNotContactListRequest();
      },
      onFailed: () => {
        resetUpdateDoNotContactListRequest();
        onHideAddModifyDoNotContactListModal();
        executeOnErrorWithErrorCheck({
          error: updateDoNotContactListRequestError,
          onError: () => {
            toaster.error(updateDoNotContactListRequestError?.message);
          },
        });
      },
    });
  }, [updateDoNotContactListRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: deleteDoNotContactListRequestStatus,
      onSuccess: () => {
        toaster.success('List deleted successfully');

        if (doNotContactListsPaginationOptions?.itemCount === 1) {
          onFiltersChange({
            page: 1,
          });
        } else {
          sendGetDoNotContactListsRequest(filters);
        }

        resetDeleteDoNotContactListRequest();
        onHideDeleteDoNotContactListModal();
      },
      onFailed: () => {
        resetDeleteDoNotContactListRequest();
        onHideDeleteDoNotContactListModal();
        executeOnErrorWithErrorCheck({
          error: deleteDoNotContactListRequestError,
          onError: () => {
            toaster.error(deleteDoNotContactListRequestError?.message);
          },
        });
      },
    });
  }, [deleteDoNotContactListRequestStatus]);

  const isLoading = getIsRequestPending(getDoNotContactListsRequestStatus);

  const renderTableJSX = () => {
    if (
      doNotContactLists?.length === 0 &&
      !isLoading &&
      filters.search === ''
    ) {
      return (
        <DoNotContactListEmptyUI
          icon={<DNCEmptyList />}
          title={
            hasPermission(Permissions.DNC_WRITE)
              ? 'Get started by creating a list to manage domains and emails you want to exclude from your outreach'
              : 'Contact your agency owner to manage domains and emails you want to exclude from your outreach'
          }
          {...(hasPermission(Permissions.DNC_WRITE) && {
            btnLabel: 'Add List',
            btnHandler: () => {
              onShowAddModifyDoNotContactListModal('add');
            },
          })}
        />
      );
    }

    if (doNotContactLists?.length === 0 && !isLoading) {
      return (
        <DoNotContactListEmptyUI
          icon={<NoDNCListFound />}
          title="No Search Result"
          desc="We can’t find any list matching your search."
        />
      );
    }

    return (
      <DoNotContactListTable
        doNotContactLists={doNotContactLists}
        doNotContactListsPaginationOptions={doNotContactListsPaginationOptions}
        filters={filters}
        onFiltersChange={onFiltersChange}
        onAction={onAction}
        isLoading={isLoading}
      />
    );
  };

  const {
    home: { subscription },
  } = store.getState();

  return (
    <div className="do-no-contact-list">
      <DoNotContactListHeader
        filters={filters}
        onFiltersChange={onFiltersChange}
        onAddList={onShowAddModifyDoNotContactListModal}
        isAgencyConfigRequestPending={getIsRequestPending(
          agencyConfigRequestStatus,
        )}
      />

      <div className="do-not-contact-banner">
        {subscription?.planCode === SubscriptionPlans.FREE && <HeaderBanner />}
      </div>

      {renderTableJSX()}

      <AddModifyDoNotContactList
        show={addModifyDoNotContactListModalState.show}
        action={addModifyDoNotContactListModalState.action}
        isLoading={
          getIsRequestPending(createDoNotContactListRequestStatus) ||
          getIsRequestPending(updateDoNotContactListRequestStatus)
        }
        defaultDoNotContactList={selectedDoNotContactList}
        onClose={onHideAddModifyDoNotContactListModal}
        onAddDoNotContactList={sendCreateDoNotContactListRequest}
        onUpdateDoNotContactList={sendUpdateDoNotContactListRequest}
        agencyClients={agencyClients}
        sendGetAgencyClientsDropdownRequest={
          sendGetAgencyClientsDropdownRequest
        }
      />

      <DeleteDoNotContactList
        show={deleteDoNotContactListModal}
        title="Delete List"
        contents={[
          'Are you sure you want to delete the selected list?',
          'Once deleted, all the emails and domains mentioned in this list will be whitelisted, and all the related prospects will become active.',
        ]}
        isLoading={getIsRequestPending(deleteDoNotContactListRequestStatus)}
        onHide={onHideDeleteDoNotContactListModal}
        onSubmit={onDeleteDoNotContactList}
      />
    </div>
  );
};

export default DoNotContactList;
