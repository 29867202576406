import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Button } from '@saleshandy/design-system';

import EmailsAndDomainsInput from '../../../do-not-contact/components/do-not-contact-list-details/components/do-not-contact-list-details-input/emails-and-domains-input';

import type { IProps } from './admin-setting-exclusion-input-container';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';
import toaster, { Theme } from '../../../../../../shared/toaster';
import { getExclusionListPaginationInitialState } from '../../admin-settings-slice';

const AdminSettingExclusionInput: React.FC<IProps> = ({
  exclusionList,
  exclusionListPaginationOptions,

  sendUpdateExclusionListRequest,
  updateExclusionListRequestStatus,
  updateExclusionListRequestMessage,
  updateExclusionListRequestError,
  resetUpdateExclusionRequestStatus,

  getExclusionListRequestStatus,
  sendGetExclusionListRequest,

  handleLoadMore,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [emailsAndDomains, setEmailsAndDomains] = useState<string[]>([]);
  const [removedIds, setRemovedIds] = useState<number[]>([]);
  const [newAddedEmailsAndDomains, setNewAddedEmailsAndDomains] = useState<
    string[]
  >([]);
  const isDirty = useRef<boolean>(false);

  useEffect(
    () => () => {
      setEmailsAndDomains([]);
      setIsError(false);
      isDirty.current = false;
    },
    [],
  );

  const exclusionListStrings = useMemo(() => {
    // Extract entities from complete exclusion list
    const emailsAndDomainsValues =
      exclusionList?.map((exclusion) => exclusion.entity) || [];

    if (emailsAndDomainsValues?.length > 0) {
      // Filter out removed IDs from exclusionList
      const filteredExclusionList = emailsAndDomainsValues.filter(
        (emailOrDomain) =>
          !removedIds.some((id) =>
            exclusionList.find(
              (exclusion) =>
                exclusion.entity === emailOrDomain && exclusion.id === id,
            ),
          ),
      );

      // Add newly added items
      const updatedEmailsAndDomains = [
        ...filteredExclusionList,
        ...newAddedEmailsAndDomains,
      ];

      setEmailsAndDomains(updatedEmailsAndDomains);
    }

    return emailsAndDomainsValues;
  }, [exclusionList]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateExclusionListRequestStatus,
      onSuccess: () => {
        toaster.success(updateExclusionListRequestMessage);
        isDirty.current = false;
        setEmailsAndDomains([]);
        setRemovedIds([]);
        setNewAddedEmailsAndDomains([]);
        resetUpdateExclusionRequestStatus();
        sendGetExclusionListRequest(getExclusionListPaginationInitialState());
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: updateExclusionListRequestError,
          onError: () => {
            toaster.error(
              updateExclusionListRequestError.message || 'Something went wrong',
              {
                theme: Theme.New,
              },
            );
          },
        });
        resetUpdateExclusionRequestStatus();
      },
    });
  }, [updateExclusionListRequestStatus]);

  const handleOnSave = useCallback(() => {
    sendUpdateExclusionListRequest({
      itemsToAdd: newAddedEmailsAndDomains,
      itemsToRemove: removedIds,
    });
  }, [emailsAndDomains, exclusionList]);

  const isLoading = getIsRequestPending(updateExclusionListRequestStatus);
  const isListUpdating = getIsRequestPending(getExclusionListRequestStatus);

  return (
    <div className="do-not-contact-list-details--input-wrapper admin-settings-exclusion-input mb-0">
      <EmailsAndDomainsInput
        emailsAndDomains={emailsAndDomains}
        setEmailsAndDomains={(updatedEmailsAndDomains) => {
          // Remove IDs
          const removedItems =
            exclusionList
              ?.filter(
                (exclusion) =>
                  !updatedEmailsAndDomains.includes(exclusion.entity),
              )
              ?.map((item) => item.id) || [];

          // New Added
          const newlyAddedItems =
            updatedEmailsAndDomains.filter(
              (emailOrDomain) => !exclusionListStrings.includes(emailOrDomain),
            ) || [];

          // Set state
          setRemovedIds(removedItems);
          setNewAddedEmailsAndDomains(newlyAddedItems);

          // Check if there are any changes
          isDirty.current =
            removedItems?.length > 0 || newlyAddedItems?.length > 0;

          setEmailsAndDomains(updatedEmailsAndDomains);
        }}
        isError={isError}
        setIsError={setIsError}
        isMaxedEmailAdded={false}
        hasMore={
          exclusionListPaginationOptions.currentPage <
            exclusionListPaginationOptions.totalPages && !isListUpdating
        }
        onLoadMore={handleLoadMore}
        loadMoreLabel={`+${
          exclusionListPaginationOptions.totalItems -
          exclusionListPaginationOptions.currentPage *
            exclusionListPaginationOptions.itemsPerPage
        } more`}
        isLoading={isListUpdating}
        placeholder="Add emails or domains with comma, semicolon, space or line breaks"
      />

      <Button
        onClick={handleOnSave}
        isLoading={isLoading}
        disabled={isLoading || isListUpdating || isError || !isDirty.current}
        loadingText="Saving..."
        className="admin-settings-exclusion-input-save-btn"
      >
        Save
      </Button>
    </div>
  );
};

export default AdminSettingExclusionInput;
