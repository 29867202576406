import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import { IProps } from './types';
import PremiumFeatureIndicator from '../../../../../../shared/components/premium-feature-indicator';
import { Placement } from '../../../../../../shared/design-system/components/overlay';
import LinkBadge from '../../../../../../shared/design-system/components/atoms/link-badge';
import { GlobalSettings } from '../../../../../../shared/components/global-settings-wrapper';

const ApiTokenHeader: React.FC<IProps> = ({
  onClick: onCreateApiTokenClickHandler,
  subscriptionPlan,
}) => {
  const { t } = useTranslation();

  return (
    <GlobalSettings.Header>
      <GlobalSettings.HeaderLeft>
        <GlobalSettings.HeaderTitle
          title="API Keys"
          isToolTip={true}
          tooltipText="Your API keys need to be treated as securely as any other password."
        />

        <LinkBadge
          label="View API Docs"
          url={process.env.REACT_APP_OPEN_API_URL}
        />
      </GlobalSettings.HeaderLeft>
      <GlobalSettings.HeaderRight>
        <div className="api-token-header">
          <div className="api-token-create-button">
            {/* TODO: refactor subscription plan check condition */}
            <PremiumFeatureIndicator
              placement={Placement.BottomEnd}
              className="mr-2"
            />
            <Button
              variant={Button.Variant.Primary}
              onClick={onCreateApiTokenClickHandler}
              className="header-btn"
            >
              {t('labels.create_api_key')}
            </Button>
          </div>
        </div>
      </GlobalSettings.HeaderRight>
    </GlobalSettings.Header>
  );
};

export default ApiTokenHeader;
