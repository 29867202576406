/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect } from 'react';
import { Alert, Button } from '@saleshandy/design-system';
import { AlertCircle } from '@saleshandy/icons';

import type { IProps } from './email-verification-container';

import ImageIcon from '../../../../../../../../shared/components/images/image-icon';
import EmailVerificationContent from '../../../../../../../../shared/design-system/components/molecules/email-verification-content';
import TagProspectsContent from '../../../../../../../../shared/design-system/components/molecules/tag-prospects-content/tag-prospects-content';
import { parseTagPayload } from '../../../../../../../../shared/utils/tags-payload-parser';
import { EmailVerificationProcess } from '../../../../../../../../shared/design-system/components/molecules/email-verification-content/enum';
import { Images } from '../../../../../../../../shared/app-constants';
import { RequestStatus } from '../../../../../../../../shared/enums/request-status';
import { ProductTour } from '../../../../../../../../shared/enums/product-tour';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';

enum ButtonIdentifier {
  SKIP_VERIFICATION = 'skip',
  CONTINUE_VERIFICATION = 'continue',
}

const EmailVerification: React.FC<IProps> = ({
  onClick,
  tags,
  fileData,
  onBuyNow,
  isPurchaseEmailVerificationCreditsLoading,
  sendGetEmailVerificationCreditsRequestStatus,
  getEmailVerificationCreditsResponse,
  sendGetTagsRequest,
  isEmailVerification,
  handleOnFinishWithoutVerification,
  isRequestPending,
  sendGetEmailVerificationCreditsRequest,
}) => {
  const [includeRiskyContact] = useState<boolean>(false);

  const [buttonIdentifier, setButtonIdentifier] = useState<string>(null);

  const [tagsToAdd, setTagsToAdd] = useState([]);
  const [
    isEvPurchaseActionRequired,
    setIsEvPurchaseActionRequired,
  ] = useState<boolean>(false);
  const [remainingCredit, setRemainingCredit] = useState<number>(0);
  const [isRenderContentLoading, setIsRenderContentLoading] = useState<boolean>(
    true,
  );
  const [verifyProspect, setVerifyProspect] = useState(true);

  const onButtonClickHandler = (
    includeRiskyContact: boolean,
    addVerification: boolean,
    buttonIdentifier: ButtonIdentifier,
  ) => {
    setButtonIdentifier(buttonIdentifier);
    onClick(includeRiskyContact, addVerification, parseTagPayload(tagsToAdd));
  };

  const buyCreditsHandler = () => {
    if (!isPurchaseEmailVerificationCreditsLoading) {
      setButtonIdentifier(null);
      onBuyNow(remainingCredit);
    }
  };

  useEffect(() => {
    if (isEmailVerification) {
      const { importedContactsCount } = fileData;
      const { creditsAvailable } = getEmailVerificationCreditsResponse || {
        creditsAvailable: 0,
      };
      const isActionRequired = importedContactsCount > creditsAvailable;

      setIsEvPurchaseActionRequired(isActionRequired);

      if (isActionRequired) {
        setRemainingCredit(importedContactsCount - creditsAvailable);
      }
    }
  }, [isEmailVerification, getEmailVerificationCreditsResponse, fileData]);

  useEffect(() => {
    sendGetTagsRequest();
  }, []);

  useEffect(() => {
    if (isEmailVerification) {
      sendGetEmailVerificationCreditsRequest();
    }
  }, [isEmailVerification]);

  useEffect(() => {
    const isLoading =
      isEmailVerification &&
      sendGetEmailVerificationCreditsRequestStatus === RequestStatus.Pending &&
      getEmailVerificationCreditsResponse === null;

    setIsRenderContentLoading(isLoading);
  }, [
    isEmailVerification,
    sendGetEmailVerificationCreditsRequestStatus,
    getEmailVerificationCreditsResponse,
  ]);

  const renderButton = () => {
    if (!isEmailVerification) {
      // Case: Email verification is OFF in admin setting
      return (
        <Button
          id={ProductTour.ReviewAndImport}
          onClick={() =>
            handleOnFinishWithoutVerification(parseTagPayload(tagsToAdd))
          }
          isLoading={isRequestPending}
          disabled={isRequestPending}
          loadingText="Importing..."
        >
          Import
        </Button>
      );
    }

    // Cases: When email verification is ON in admin setting
    if (!verifyProspect) {
      // Case 1: User want to skip verification
      return (
        <Button
          onClick={() =>
            onButtonClickHandler(
              includeRiskyContact,
              false,
              ButtonIdentifier.SKIP_VERIFICATION,
            )
          }
          isLoading={buttonIdentifier === ButtonIdentifier.SKIP_VERIFICATION}
          disabled={buttonIdentifier === ButtonIdentifier.SKIP_VERIFICATION}
          loadingText="Importing..."
        >
          Import
        </Button>
      );
    }

    // Case: User want to verify prospects, and user does have the required credits
    return (
      <Button
        id={ProductTour.ReviewAndImport}
        onClick={() =>
          onButtonClickHandler(
            includeRiskyContact,
            true,
            ButtonIdentifier.CONTINUE_VERIFICATION,
          )
        }
        isLoading={buttonIdentifier === ButtonIdentifier.CONTINUE_VERIFICATION}
        disabled={
          buttonIdentifier === ButtonIdentifier.CONTINUE_VERIFICATION ||
          isEvPurchaseActionRequired
        }
        loadingText="Importing..."
      >
        Import
      </Button>
    );
  };

  return (
    <div className="import-contact-stage">
      <div className="email-verification overflow-auto import-contact-stage-content">
        <TagProspectsContent
          tags={tags}
          tagsToAdd={tagsToAdd}
          setTagsToAdd={setTagsToAdd}
          placeholder="Add tags to categorize your prospects..."
        />

        {isEmailVerification && (
          <div className="email-verification-info-container">
            {!isRenderContentLoading ? (
              <>
                <EmailVerificationContent
                  verificationProcess={EmailVerificationProcess.ImportFlow}
                  availableCredits={
                    getEmailVerificationCreditsResponse?.creditsAvailable
                  }
                  requiredCredits={fileData.importedContactsCount}
                  showVerifyProspectCheckbox={true}
                  verifyProspect={verifyProspect}
                  setVerifyProspect={setVerifyProspect}
                />

                {verifyProspect && isEvPurchaseActionRequired && (
                  <Alert
                    className="buy-credit-alert"
                    variant="alert"
                    icon={AlertCircle}
                    description={
                      <div className="buy-credit-alert-desc d-flex align-items-center">
                        <p className="m-0">
                          To send emails to only verified prospects
                        </p>
                        <p
                          className="m-0"
                          {...accessibleOnClick(() => {
                            buyCreditsHandler();
                          })}
                        >
                          Add Credits
                        </p>
                      </div>
                    }
                  />
                )}
              </>
            ) : (
              <div className="content-loader">
                <ImageIcon src={Images.Loader} />
              </div>
            )}
          </div>
        )}
      </div>

      {!isRenderContentLoading && (
        <div className="import-contact-stage-footer">
          <div className="import-contact-stage-footer--left" />

          <div className="import-contact-stage-footer--right">
            {renderButton()}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailVerification;
