import { SubscriptionPlans } from './subscription-plans';
import { getUserCurrentPlan } from './user-details';

export const isTrailPlan = (planType: SubscriptionPlans) =>
  planType === SubscriptionPlans.TRIAL;

export const isFreePlan = () => {
  const planCode = getUserCurrentPlan();

  return planCode === SubscriptionPlans.FREE;
};

export const isFreeArTrailPlan = (planCode: SubscriptionPlans) =>
  planCode === SubscriptionPlans.FREE || planCode === SubscriptionPlans.TRIAL;
