import api from '../../../api';
import { ResponseSuccess } from '../../../shared/types/response';
import { PaginationOptions } from '../components/admin-settings/types';
import {
  UpdateAdminSettingsExcludeList,
  UpdateAdminSettingsRequestPayload,
} from '../types/request-payload';

export const getAdminSettings = async () =>
  api.get<ResponseSuccess>(`/accounts`);

export const updateAdminSettings = async (
  payload: UpdateAdminSettingsRequestPayload,
) => api.patch<ResponseSuccess>(`/accounts`, payload);

export const getExclusionList = async (payload: PaginationOptions) =>
  api.get<ResponseSuccess>(`/unibox/exclude-list`, {
    params: {
      pageNumber: payload.currentPage,
      pageLimit: payload.itemsPerPage,
    },
  });

export const updateExclusionList = async (
  payload: UpdateAdminSettingsExcludeList,
) => api.post<ResponseSuccess>('/unibox/exclude-list', payload);
