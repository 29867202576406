import React, { useMemo } from 'react';
import Pagination from '../../pagination';
import { constants } from '../../../../enums/constants';

const PaginationWrapper: React.FC<any> = (props) => {
  const {
    dataSize,
    page,
    showRowsPerPage,
    isNewPagination,
    onPageChange,
    sequenceFixedPageSize,
    fixedPageSize,
  } = props;

  let defaultSize = sequenceFixedPageSize
    ? constants.DEFAULT_SEQUENCE_PAGE_SIZE
    : constants.DEFAULT_PAGE_SIZE;
  if (fixedPageSize) {
    defaultSize = fixedPageSize || defaultSize;
  }
  const perPageLimit = isNewPagination ? showRowsPerPage : defaultSize;

  const totalPages = useMemo(() => {
    const totalPagesCount = Math.ceil(dataSize / perPageLimit);

    if (page > totalPagesCount) {
      console.log("Case: Current page shouldn't be greater than total pages");
      onPageChange({ page: 1, perPageLimit });
    }

    return totalPagesCount;
  }, [dataSize, perPageLimit]);

  return (
    <Pagination
      currentPage={page}
      totalPages={totalPages}
      onChange={onPageChange}
    />
  );
};

export default PaginationWrapper;
