import React from 'react';
import { EmailWarmupStatus } from '../../../../../../email-warmup/enums';

const getEmailWarmupStatusLabelAndStatus = (
  warmupStatus: number,
): {
  label: string;
  tooltip: string;
} => {
  if (warmupStatus === EmailWarmupStatus.Stopped) {
    return {
      label: 'Stopped',
      tooltip:
        'Email warm-up is stopped because the of the technical issues. Click to know more.',
    };
  }
  if (warmupStatus === EmailWarmupStatus.Suspended) {
    return {
      label: 'Suspended',
      tooltip:
        'Email warm-up is suspended for this email account. Contact us to know more.',
    };
  }
  if (warmupStatus === EmailWarmupStatus.Paused) {
    return {
      label: 'Paused',
      tooltip: 'You have paused email warm-up for this email account.',
    };
  }
  return {
    label: 'Running',
    tooltip: 'Email warm-up is running for this email account',
  };
};

const EmailWarmupStatusCell = ({ warmupStatus }) => {
  const { label } = getEmailWarmupStatusLabelAndStatus(Number(warmupStatus));

  return (
    <>
      <div className={`${label.toLowerCase()}`}>
        <span className="badge-dot" />
        <span className="badge-label">{label}</span>
      </div>
    </>
  );
};

export default EmailWarmupStatusCell;
