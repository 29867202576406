import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import EmailAccountsFiltersModal from './email-accounts-filters-modal';
import { getContactSequenceListRequest } from '../../../../../sequence/extra-actions';

import { GetAgencyClientDropdownRequestPayload } from '../../../../../agency-client-management/types/request-payload';
import { getAgencyClientsDropdown } from '../../../../../agency-client-management/extra-actions';
import { getMembersListRequest } from '../../../users-and-teams/extra-actions';
import { UserStatusKeys } from '../../../../enums/users-and-teams';
import { EmailAccountsFilters } from '../../types';
import {
  getEmailAccountsRequest,
  getEmailAccountTagsRequest,
} from '../../email-account-slice';

const mapStateToProps = (state: RootState) => ({
  sequences: state.sequence.contactSequence,
  tags: state.emailAccount.emailAccountTagsList,
  clients: state.agencyClient.clients,
  owners: state.usersAndTeams.membersList,
});

const mapDispatchToProps = {
  sendGetSequenceList: () => getContactSequenceListRequest(),
  sendGetOwnersList: () => getMembersListRequest([UserStatusKeys.Active]),
  sendGetTagsRequest: () => getEmailAccountTagsRequest(),
  sendGetAgencyClientsDropdownRequest: (
    payload: GetAgencyClientDropdownRequestPayload,
  ) => getAgencyClientsDropdown(payload),
  sendGetEmailAccountsRequest: (filters: EmailAccountsFilters) =>
    getEmailAccountsRequest(filters),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show: boolean;
  onClose: VoidFunction;
  emailAccountsFilters: EmailAccountsFilters;
  onFiltersChange: (partialFilters: Partial<EmailAccountsFilters>) => void;
  // getEmailAccountsWithFilters: (params?: EmailAccountsFilters) => void;
};

export default connector(EmailAccountsFiltersModal);
