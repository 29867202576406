import { EmailAccount } from '../../types/email-account';
import { EmailAccountsSortBy } from '../../enums/email-account';
import { Order } from '../../../../shared/enums/order';
import { SMTPIMAP } from '../../enums/smtp-or-imap';

export interface IState {
  showAddEmailAccountModal: boolean;
  showBulkModal: boolean;
  showBulkEmailUpdateModal: boolean;
  showDisconnectEmailAccountModal: boolean;
  showDeleteEmailAccountModal: boolean;
  showEmailAccountQuotaChangeConfirmationModal: boolean;
  actionEmailAccount: EmailAccount;
  quotaChangeConfirmationMessage: string;
  showNoSeatAvailableForEmailModal: boolean;
  showConnectAndSaveSMTPIMAPModel: boolean;
  emailAccountId: number;
  showReactivateSubscriptionModal: boolean;
  restrictionErrorModalMeta: {
    show: boolean;
    planName: string;
  };
  errorLogAccountType: SMTPIMAP;
  isErrorLogModalVisible: boolean;
  errorLog: string;
  resetSelected: boolean;
  showBulkEmailAccountUpdateConfirmationModal: boolean;
  showTrulyInboxEmailSyncConsentModal: boolean;
}

// eslint-disable-next-line import/no-cycle
export type { IProps } from './email-account-container';

export enum Filters {
  ClientAssociated = 'clientIds',
  EmailServiceProviders = 'emailServiceProvider',
  Tags = 'tagIds',
  Sequences = 'sequenceIds',
  EmailAccountStatus = 'status',
  EmailAccountAddedBy = 'owners',
}

export type EmailAccountsFilters = Record<Filters, any[]> & {
  page: number;
  limit: number;
  search: string;
  sortByKey: EmailAccountsSortBy;
  order: Order;
};

export type BulkSelectedEmailAccounts = {
  isAllEmailAccountsSelected?: boolean;
  selectionCount?: boolean;
  deSelectedEmailAccountIds?: any[];
};

export enum EmailAccountSortBy {
  Owner = 'owner',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}
