export enum ConflictAction {
  Overwrite = 'overwrite',
  NoUpdate = 'noUpdate',
  AddMissingFields = 'addMissingFields',
}

export enum SequenceProspectStatus {
  Active = 1,
  Paused = 2,
  Replied = 3,
  Unsubscribed = 4,
  Finished = 5,
  BlackListed = 6,
  Bounced = 7,
  Opened = 8,
}

export enum ContactStatusFilterEnum {
  Total = 'total',
  Active = 'active',
  Open = 'open',
  Replied = 'replied',
  Bounced = 'bounced',
  Unsubscribed = 'unsubscribed',
  Finished = 'finished',
  Clicked = 'clicked',
  Failed = 'failed',
  NotContacted = 'notContacted',
  Contacted = 'contacted',
  SentNotOpen = 'sentNoOpen',
  OpenNotClick = 'openedNoClicked',
  OpenNotReply = 'openedNoReply',
  ClickedNoReply = 'clickedNoReply',
}

export enum ContactStatusType {
  Active = 'active',
  Finished = 'finished',
  Replied = 'replied',
  Bounced = 'bounced',
  HardBounced = 'hardBounced',
  SoftBounced = 'softBounced',
  Unsubscribed = 'unsubscribed',
  Blacklisted = 'blacklisted',
  Open = 'open',
  Clicked = 'clicked',
  Subscribed = 'subscribed',
  Paused = 'paused',
  Resume = 'resume',
  Failed = 'failed',
}

export enum ContactStatusTypeDisplayName {
  Total = 'Total',
  active = 'Active',
  open = 'Opened',
  replied = 'Replied',
  bounced = 'Bounced',
  unsubscribed = 'Unsubscribed',
  finished = 'Finished',
  clicked = 'Clicked',
  blacklisted = 'Blacklisted',
  subscribed = 'Subscribed',
  paused = 'Paused',
  resume = 'Resume',
  failed = 'Failed',
  NotContacted = 'Not Contacted',
  Contacted = 'Contacted',
  SentNotOpen = 'Sent, No Open',
  OpenNotClick = 'Opened, No Click',
  OpenNoReply = 'Opened, No Reply',
  ClickedNoReply = 'Clicked, No Reply',
}

export enum ProspectOutcome {
  Interested = 'interested',
  MeetingBooked = 'meetingBooked',
  Closed = 'closed',
  OutOfOffice = 'outOfOffice',
  NotInterested = 'notInterested',
  NotNow = 'notNow',
  DoNotContact = 'doNotContact',
  Uncategorized = 'uncategorized',
}

export enum ProspectOutcomeDisplayName {
  Interested = 'Interested',
  MeetingBooked = 'Meeting Booked',
  Closed = 'Closed',
  OutOfOffice = 'Out of Office',
  NotInterested = 'Not Interested',
  NotNow = 'Not Now',
  DoNotContact = 'Do Not Contact',
  Uncategorized = 'Uncategorized',
}

export enum ContactVerificationStatus {
  Unverified = 'skip',
  Valid = 'valid',
  Risky = 'risky',
  Bad = 'bad',
}

export enum ContactVerificationStatusTypeDisplayName {
  Unverified = 'Unverified',
  Valid = 'Valid',
  Risky = 'Risky',
  Bad = 'Bad',
  InProgress = 'In Progress',
}

export enum ZeroBounceSubStatusSaleshandy {
  ALIAS_EMAIL = 'Alias email',
  INVALID_DOES_NOT_ACCEPT_EMAIL = 'Invalid_Does not accept mail',
  CORRECTLY_FORMATTED_EMAIL = 'Correctly formatted email',
  INVALID_FAILED_SYNTAX = 'Invalid_Failed syntax',
  LIKELY_SECONDARY_EMAIL = 'Likely secondary email',
  INVALID_POSSIBLE_TYPO = 'Invalid_Possible typo',
  INVALID_MAILBOX_NOT_FOUND = 'Invalid_Mailbox not found',
  INVALID_NO_DNS_RECORDS = 'Invalid_No DNS records',
  INVALID_MAILBOX_FULL = 'Invalid_Mailbox full',
  INVALID_UNROUTABLE_IP_ADDRESS = 'Invalid_Unroutable ip address',
  DO_NOT_MAIL_GLOBALLY_BLACKLISTED = 'Do not mail_Globally blacklisted',
  DO_NOT_MAIL_POSSIBLE_SPAM_TRAP = 'Do not mail_Possible spam trap',
  DO_NOT_MAIL_ROLE_BASED_EMAILS = 'Do not mail_Role based emails',
  DO_NOT_MAIL_TEMPORARY_EMAIL_ACCOUNTS = 'Do not mail_Temporary email accounts',
  DO_NOT_MAIL_BOT_CREATED_EMAIL_ACCOUNTS = 'Do not mail_Bot created email accounts',
  DO_NOT_MAIL_ROLE = 'Do not mail_Role based catch all',
  DO_NOT_MAIL_MX_FORWARDED = 'Do not mail_MX forwarded',
  UNKNOWN_NOT_VALIDATED = 'Unknown_Not validated',
  UNKNOWN_WHILE_VALIDATING_ERROR = 'Unknown_While validating error',
  UNKNOWN_FAILED_SMTP_CONNECTION = 'Unknown_Failed smtp connection',
  UNKNOWN_SERVER_DISCONNECTED_WHILE_VALIDATING = 'Unknown_Server disconnected while validating',
  UNKNOWN_NOT_VALIDATED_TEMPORARILY = 'Unknown_Not validated temporarily',
  UNKNOWN_SERVER_UNRESPONSIVE = 'Unknown_Server unresponsive',
  UNKNOWN_TEMPORARY_SERVER_ERROR = 'Unknown_Temporary server error',
  UNKNOWN_TIMEOUT = 'Unknown_Timeout',
  CATCH_ALL = 'Catch all',
  SPAMTRAP = 'Spamtrap',
  ABUSE = 'Abuse',
}

export enum ProspectCreationSource {
  manually_created = 'manually_created',
  csv_import = 'csv_import',
  chrome_extension = 'chrome_extension',
  api = 'api',
  lead_finder = 'lead_finder',
  unified_inbox = 'unified_inbox',
  undefined = 'null',
}

export enum CreationSourceDisplayName {
  Manually_Created = 'Manually Created',
  CSV_Import = 'CSV Import',
  Chrome_Extention = 'Chrome Extension',
  API = 'API',
  Lead_Finder = 'Lead Finder',
  Unified_Inbox = 'Unified Inbox',
  Undefined = 'Undefined',
}

export enum StatusColor {
  COLOR_TOTAL = '#0C1D33',
  COLOR_ACTIVE = '#0004C9',
  COLOR_FINISH = '#389E0D',
  COLOR_OPEN = '#13C2C2',
  COLOR_CLICKED = '#9254DE',
  COLOR_REPLIED = '#FA8C16',
  COLOR_BOUNCED = '#FF0000',
  COLOR_UNSUBSCRIBED = '#0C1D33',
}
export enum ContactTotal {
  TOTAL = 'total',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum ProspectActivityTabs {
  All = 'all',
  Sent = 'email-sent',
  Opened = 'email-opened',
  Clicked = 'link-clicked',
  Replied = 'email-replied',
}
