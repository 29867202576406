import React, { useState } from 'react';
import classNames from 'classnames';
import { ProgressBar } from 'react-bootstrap';
import {
  OverlayTooltip,
  Placement,
} from '../../design-system/components/overlay';
import { AttachmentUploadStatus } from '../../editor/types';
import { accessibleOnClick } from '../../utils/accessible-on-click';
import ImageIcon from '../images/image-icon';
import { getAttachmentMeta, getFileName } from './helpers';
import type { IProps } from './types';
import api from '../../../api';
import toaster, { Theme } from '../../toaster';
import Icon from '../../design-system/components/atoms/icon';

const Attachment: React.FC<IProps> = ({
  attachment,
  attachmentMeta,
  handleAttachmentDelete,
  handleAttachmentReupload,
  showAttachmentDeleteButton = true,
}) => {
  const [deletingAttachment, setDeletingAttachment] = useState(false);

  const { icon, name, size } = getAttachmentMeta(attachment);

  const handleDownloadAttachment = () => {
    if (
      attachment.status === AttachmentUploadStatus.UploadSuccess ||
      attachment.status === AttachmentUploadStatus.Existing
    ) {
      const url = `/attachment-broker/attachment/${attachment.attachmentId}`;

      api
        .get(url, {
          responseType: 'blob',
        })
        .then((response) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([response as any]));
          link.setAttribute('download', getFileName(attachment.file.name));
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch(() => {
          toaster.error('Oops! Attachment download has failed.', {
            theme: Theme.New,
          });
        });
    }
  };

  const handleOnAttachmentDelete = () => {
    handleAttachmentDelete(
      attachment?.attachmentIdentifier,
      attachment?.attachmentId,
    );
    setDeletingAttachment(true);
  };

  const getAttachmentLabel = () => {
    if (deletingAttachment) {
      return <p className="deleting">Deleting...</p>;
    }

    if (attachment.status === AttachmentUploadStatus.UploadFailed) {
      return <p className="upload-failed">Upload failed</p>;
    }

    return <p>{size}</p>;
  };

  const attachmentNameId = `${
    attachment.attachmentId || attachment.attachmentIdentifier
  }`;

  const attachmentClassNames = classNames([
    'attachment',
    {
      'attachment--upload-failed':
        attachment.status === AttachmentUploadStatus.UploadFailed,
      'attachment--uploading':
        attachment.status === AttachmentUploadStatus.Uploading,
    },
  ]);

  return (
    <div className={attachmentClassNames}>
      <div className="attachment--meta">
        <div className="attachment--icon">
          <ImageIcon src={icon} />
        </div>
        <div className="attachment--details">
          <OverlayTooltip
            placement={Placement.Bottom}
            text={
              <div className="d-flex flex-column">
                <span>{getFileName(attachment.file.name)}</span>
                {(attachment.status === AttachmentUploadStatus.UploadSuccess ||
                  attachment.status === AttachmentUploadStatus.Existing) && (
                  <span className="font-10 font-normal gray-txt-13">
                    (Click to download)
                  </span>
                )}
              </div>
            }
          >
            <h3
              id={attachmentNameId}
              {...accessibleOnClick(handleDownloadAttachment)}
            >
              {name}
            </h3>
          </OverlayTooltip>

          {getAttachmentLabel()}
        </div>
      </div>
      <div className="d-flex">
        {attachment.status === AttachmentUploadStatus.UploadFailed && (
          <div
            className="attachment--action mr-2"
            {...accessibleOnClick(() => handleAttachmentReupload(attachment))}
          >
            <Icon identifier="rotate" />
          </div>
        )}
        {showAttachmentDeleteButton && !deletingAttachment && (
          <OverlayTooltip text="Remove attachment">
            <div
              className="attachment--action"
              {...accessibleOnClick(handleOnAttachmentDelete)}
            >
              <Icon identifier="close" />
            </div>
          </OverlayTooltip>
        )}
      </div>

      {attachment.status === AttachmentUploadStatus.Uploading && (
        <div className="attachment--progress">
          <ProgressBar now={attachmentMeta?.percentage} />
        </div>
      )}
    </div>
  );
};

export default Attachment;
