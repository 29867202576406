import Tabs, { TabPane } from 'rc-tabs';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { SkeletonLoading } from '@saleshandy/design-system';
import { Images } from '../../../../../../shared/app-constants';
import { AvatarWithName } from '../../../../../../shared/design-system/components/atoms/avatar-with-name/avatar-with-name';
import Table from '../../../../../../shared/design-system/components/organisms/table';
import {
  Column,
  PaginationShowHide,
} from '../../../../../../shared/design-system/components/organisms/table/types';
import { PaginationQueryParams } from '../../../../../../shared/types/request';
import { capitalize } from '../../../../../../shared/utils';
import {
  DefaultOutcome,
  CustomOutcome,
} from '../../../../types/custom-outcome';
import {
  generateOutcomesActionsColumn,
  getCustomOutcomesColumns,
  getDefaultTableWrapper,
} from './helpers/custom-outcome-column-data';
import { OutcomeContentTabs } from './types';
import HeaderBanner from '../../../header-banner';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import SearchInput from '../../../../../../shared/design-system/components/atoms/search-input';
import { IconPlace } from '../../../../../../shared/design-system/components/input/enums';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import Icon from '../../../../../../shared/design-system/components/atoms/icon';
import ImageIcon from '../../../../../../shared/components/images/image-icon';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';

type IProps = {
  customOutcomes: CustomOutcome[];
  defaultOutcomes: DefaultOutcome[];
  onCustomOutcomePaginationOptionsChangeHandler: (
    newPartialOptions: Partial<PaginationQueryParams>,
  ) => void;
  onDefaultOutcomePaginationOptionsChangeHandler: (
    newPartialOptions: Partial<PaginationQueryParams>,
  ) => void;
  customOutcomePagination: {
    options: Required<PaginationQueryParams> & {
      totalElements: number;
      search?: string;
    };
    pagesCount: number;
    count: number;
  };
  defaultOutcomePagination: {
    options: Required<PaginationQueryParams> & {
      totalElements: number;
      search?: string;
    };
    pagesCount: number;
    count: number;
  };
  onActonHandler: (key, row) => void;
  activeTabKey: string;
  onTabChange: (string) => void;
  onCreateHandler: (event: React.MouseEvent<HTMLElement>) => void;
  subscriptionPlan: SubscriptionPlans;
  t: (x: string) => React.ReactNode;
  search: string;
  setSearch: (search: string) => void;
  onClearSearch: () => void;
  onSearch: () => void;
  isRequestPending: boolean;
};

const prepareCustomOutcomes = (customOutcomes) =>
  customOutcomes.map((field) => ({
    ...field,
    type: capitalize(field.fieldType),
  }));

const prepareDefaultOutcomes = (defaultOutcomes) =>
  defaultOutcomes.map((field) => ({
    ...field,
    type: capitalize(field.fieldType),
  }));

const CustomOutcomeTabPanes: React.FC<IProps> = ({
  customOutcomes,
  defaultOutcomes,
  onDefaultOutcomePaginationOptionsChangeHandler,
  onCustomOutcomePaginationOptionsChangeHandler,
  customOutcomePagination,
  defaultOutcomePagination,
  onActonHandler,
  activeTabKey,
  onTabChange,
  onCreateHandler,
  subscriptionPlan,
  t,
  search,
  setSearch,
  onClearSearch,
  onSearch,
  isRequestPending,
}) => {
  const renderTable = (tab, columns) => {
    if (
      customOutcomes.length === 0 &&
      tab === OutcomeContentTabs.CustomOutcomes &&
      !isRequestPending
    ) {
      return (
        <div className="empty-custom-outcomes">
          <div className="empty-outcome-inner">
            <div className="empty-outcome-image">
              <ImageIcon src={Images.EmptyData5} alt="empty-data/" />
            </div>
            <div className="empty-outcome-title">
              {t('labels.custom_outcomes_empty_list_title')}
            </div>
            <p className="empty-outcome-description">
              {t('labels.custom_outcomes_empty_list_description')}{' '}
              <a
                target="_blank"
                href="https://docs.saleshandy.com/en/articles/9157899-how-to-create-custom-prospect-outcome"
                rel="noreferrer"
              >
                Learn More
              </a>
            </p>
          </div>
        </div>
      );
    }
    return (
      <Table
        actions={generateOutcomesActionsColumn(tab)}
        newThemeActions={true}
        columns={columns}
        data={
          tab === OutcomeContentTabs.CustomOutcomes
            ? prepareCustomOutcomes(customOutcomes)
            : prepareDefaultOutcomes(defaultOutcomes)
        }
        onAction={onActonHandler}
        paginationOptions={
          tab === OutcomeContentTabs.CustomOutcomes
            ? customOutcomePagination
            : defaultOutcomePagination
        }
        onPaginationOptionsChange={
          tab === OutcomeContentTabs.CustomOutcomes
            ? onCustomOutcomePaginationOptionsChangeHandler
            : onDefaultOutcomePaginationOptionsChangeHandler
        }
        headerVisibleForGenerateColumn
        pagination={PaginationShowHide.SHOW}
        hidePageOptions
        tableWrapperClasses={getDefaultTableWrapper(
          tab,
          customOutcomePagination.count,
          defaultOutcomePagination.count,
        )}
        isNewPagination={true}
        isLoading={isRequestPending}
      />
    );
  };

  return (
    <Tabs
      activeKey={activeTabKey}
      prefixCls="bs-tabs"
      className="bs-tabs-small"
      onChange={(tabKey) => onTabChange(tabKey)}
      tabBarExtraContent={
        activeTabKey === OutcomeContentTabs.CustomOutcomes && (
          <div className="add-custom-fields-container">
            <div className="d-flex align-items-center">
              <SearchInput
                searchValue={search}
                onSearch={onSearch}
                onClearSearch={onClearSearch}
                setSearchValue={setSearch}
                searchIconPlace={IconPlace.Left}
                placeholder="Search"
                initialValue={search}
                className="prospect-outcomes--search"
              />
            </div>
            {hasPermission(Permissions.OUTCOME_WRITE) && (
              <Button
                onClick={onCreateHandler}
                variant={Button.Variant.Primary}
                className="header-btn"
                iconLeft={<Icon identifier="math-plus" />}
              >
                {t('labels.add_prospect_outcomes')}
              </Button>
            )}
          </div>
        )
      }
    >
      {[
        OutcomeContentTabs.CustomOutcomes,
        OutcomeContentTabs.SystemOutcomes,
      ].map((tab) => {
        let columns: Column[] = getCustomOutcomesColumns();

        if (tab === OutcomeContentTabs.CustomOutcomes) {
          columns = [
            ...columns,
            {
              dataField: 'user',
              text: 'Added By',
              headerAlign: 'left',
              align: 'left',
              component: (cell) => {
                if (cell) {
                  const { firstName, lastName } = cell;
                  return (
                    <AvatarWithName firstName={firstName} lastName={lastName} />
                  );
                }
                return '-';
              },
              headerLoadingSkeleton: <SkeletonLoading width={34} height={14} />,
              cellLoadingSkeleton: <SkeletonLoading width={110} height={14} />,
            },
          ];
        }

        return (
          <TabPane
            tab={
              tab === OutcomeContentTabs.CustomOutcomes
                ? t('labels.custom_outcomes')
                : t('labels.system_outcomes')
            }
            key={tab}
          >
            <div className="custom-fields-content-container__tab-content table-wrapper">
              {subscriptionPlan === SubscriptionPlans.FREE && <HeaderBanner />}
              {renderTable(tab, columns)}
            </div>
          </TabPane>
        );
      })}
    </Tabs>
  );
};

export default withTranslation()(CustomOutcomeTabPanes);
