import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withTranslation } from 'react-i18next';
import { RootState } from '../../../../store/root-reducer';
import {
  connectEmailAccountRequest,
  setDefaultEmailAccountRequest,
  disconnectEmailAccountRequest,
  getEmailAccountsRequest,
  deleteEmailAccountRequest,
  updateEmailAccountRequest,
  resetUpdateSmtpImapAccountDetails,
  resetSmtpImapTestConnection,
  resetSmtpIMapConnectResponse,
  resetSelectedEmailAccount,
  clearEmailAccountsSelection,
  resetEmailAccountTagsRequest,
  updateEmailAccountsFilters,
} from './email-account-slice';
import EmailAccounts from './email-account';
import { EmailAccountMethod } from '../../enums/email-account';
import { UpdateEmailAccountRequestPayload } from '../../types/request-payload';
import {
  updateApplicationState,
  getUserSettingsRequest,
  updateProfileProgressRequest,
} from '../../../home/home-slice';
import {
  getConnectedUsersAndEmailAccountsRequest,
  reactivateSubscriptionRequest,
} from '../billing-subscription/extra-actions';
import {
  resetConnectedUsersAndEmailAccountsRequest,
  resetReactivateSubscriptionResponse,
} from '../billing-subscription/billing-subscription-slice';
import { ReactivateResumeSubscriptionRequest } from '../billing-subscription/types';
import {
  testSMTPConnectionRequest,
  testIMAPConnectionRequest,
  connectSmtpImapAccountRequest,
  getSmtpImapAccountDetailsRequest,
  updateSmtpImapAccountDetailsRequest,
  bulkEmailUpdateRequest,
  trulyinboxEmailAccountVerifyShApiKeyRequest,
  getTrulyInboxTokenRequest,
} from './extra-actions';
import {
  ConnectSmtpImapAccountRequest,
  TestConnectionRequest,
  UpdateSMTPIMAPAccountRequest,
} from '../../types/smtp-imap';
import { UpdateProfileProgressPayload } from '../../../../shared/types/user-setting';
import { EmailAccountsFilters } from './types';
import { authenticateApiTokenWithCurrentUserRequest } from '../api-tokens/extra-actions';

const mapStateToProps = (state: RootState) => ({
  connectEmailAccountRequestStatus:
    state.emailAccount.connectEmailAccountRequest.status,
  connectEmailAccountRequestMessage:
    state.emailAccount.connectEmailAccountRequest.message,
  connectEmailAccountRequestError:
    state.emailAccount.connectEmailAccountRequest.error,

  setDefaultEmailAccountRequestStatus:
    state.emailAccount.setDefaultEmailAccountRequest.status,
  setDefaultEmailAccountRequestMessage:
    state.emailAccount.setDefaultEmailAccountRequest.message,
  setDefaultEmailAccountRequestError:
    state.emailAccount.setDefaultEmailAccountRequest.error,

  disconnectEmailAccountRequestStatus:
    state.emailAccount.disconnectEmailAccountRequest.status,
  disconnectEmailAccountRequestMessage:
    state.emailAccount.disconnectEmailAccountRequest.message,
  disconnectEmailAccountRequestError:
    state.emailAccount.disconnectEmailAccountRequest.error,

  getEmailAccountsRequestStatus:
    state.emailAccount.getEmailAccountsRequest.status,
  getEmailAccountsRequestMessage:
    state.emailAccount.getEmailAccountsRequest.message,
  getEmailAccountsRequestError:
    state.emailAccount.getEmailAccountsRequest.error,

  deleteEmailAccountRequestStatus:
    state.emailAccount.deleteEmailAccountRequest.status,
  deleteEmailAccountRequestMessage:
    state.emailAccount.deleteEmailAccountRequest.message,
  deleteEmailAccountRequestError:
    state.emailAccount.deleteEmailAccountRequest.error,

  updateEmailAccountRequestStatus:
    state.emailAccount.updateEmailAccountRequest.status,
  updateEmailAccountRequestMessage:
    state.emailAccount.updateEmailAccountRequest.message,
  updateEmailAccountRequestError:
    state.emailAccount.updateEmailAccountRequest.error,

  getCustomDomainListRequestStatus:
    state.customDomain.getCustomDomainsListRequest.status,
  getCustomDomainListRequestMessage:
    state.customDomain.getCustomDomainsListRequest.message,
  getCustomDomainListRequestError:
    state.customDomain.getCustomDomainsListRequest.error,

  getTestSMTPConnectionRequestStatus:
    state.emailAccount.testSMTPConnectionRequest.status,
  getTestIMAPConnectionRequestStatus:
    state.emailAccount.testIMAPConnectionRequest.status,
  getTestSMTPConnectionRequestMessage:
    state.emailAccount.testSMTPConnectionRequest.message,
  getTestIMAPConnectionRequestMessage:
    state.emailAccount.testIMAPConnectionRequest.message,
  getTestSMTPConnectionRequestError:
    state.emailAccount.testSMTPConnectionRequest.error,
  getTestIMAPConnectionRequestError:
    state.emailAccount.testIMAPConnectionRequest.error,
  sendTestSMTPConnectionResponse: state.emailAccount.testSMTPConnectionResponse,
  sendTestIMAPConnectionResponse: state.emailAccount.testIMAPConnectionResponse,

  getConnectSmtpImapRequestStatus:
    state.emailAccount.connectSmtpImapAccountRequest.status,
  getConnectSmtpImapRequestMessage:
    state.emailAccount.connectSmtpImapAccountRequest.message,
  getConnectSmtpImapRequestError:
    state.emailAccount.connectSmtpImapAccountRequest.error,
  sendConnectSmtpImapResponse:
    state.emailAccount.connectSmtpImapAccountResponse,

  sendGetSmtpImapAccountDetailsRequestStatus:
    state.emailAccount.getSmtpImapAccountDetailsRequest.status,
  sendGetSmtpImapAccountDetailsResponse:
    state.emailAccount.sendGetSmtpImapAccountDetailsResponse,

  sendUpdateSmtpImapAccountRequestStatus:
    state.emailAccount.updateSmtpImapAccountDetailsRequest.status,
  sendUpdateEmailAccountRequestMessage:
    state.emailAccount.updateSmtpImapAccountDetailsRequest.message,
  sendUpdateSmtpImapAccountDetailsResponse:
    state.emailAccount.sendUpdateSmtpImapAccountDetailsResponse,

  bulkEmailUpdateRequestStatus:
    state.emailAccount.bulkEmailUpdateRequest.status,
  bulkEmailUpdateRequestMessage:
    state.emailAccount.bulkEmailUpdateRequest.message,
  bulkEmailUpdateRequestError: state.emailAccount.bulkEmailUpdateRequest.error,

  authUrl: state.emailAccount.authUrl,
  emailAccounts: state.emailAccount.emailAccounts,
  isLoading: state.emailAccount.isLoading,
  emailAccountsCount: state.emailAccount.emailAccounts.length,
  isEmpty: state.emailAccount.emailAccounts.length === 0,

  subscriptionPlan: state.home.subscription?.planCode,
  planType: state.home.subscription?.planType,
  slots: state.home.subscription?.slots,
  featureUsages: state.home.featureUsages,
  showAddEmailAccountModalProp: state.home.application.showAddEmailAccountModal,

  firstName: state.home.adminDetails?.firstName,
  getConnectedUsersAndEmailAccountsRequestStatus:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest
      .status,
  getConnectedUsersAndEmailAccountsRequestError:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest.error,
  totalEmailAccounts:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsResponse
      ?.totalEmailAccounts,
  sendGetReactivateSubscriptionRequestStatus:
    state.billingAndSubscription.reactivateSubscriptionRequest.status,
  sendGetReactivateSubscriptionResponse:
    state.billingAndSubscription.reactivateSubscriptionResponse,
  sendGetReactivateSubscriptionRequestError:
    state.billingAndSubscription.reactivateSubscriptionRequest.error,
  subscriptionPlanStatus: state.home.subscription?.status,
  customerId: state.home.subscription?.customerId,
  subscriptionEndDate: state.home.subscription?.nextBillingAt,
  profileProgress: state.home.profileProgress,
  updateProfileProgressRequestStatus:
    state.home.updateProfileProgressRequest.status,
  agencyConfig: state.home.agencyConfig,

  importEmailAccountCsvRequestStatus:
    state.emailAccount.importEmailAccountCsvRequest.status,
  importEmailAccountCsvRequestMessage:
    state.emailAccount.importEmailAccountCsvRequest.message,
  isCurrentUserAuthenticatedWithApiToken:
    state.apiToken.isCurrentUserAuthenticatedWithApiToken,
  authenticateApiTokenWithCurrentUserRequestStatus:
    state.apiToken.authenticateApiTokenWithCurrentUserRequest.status,
  authenticateApiTokenWithCurrentUserRequestMessage:
    state.apiToken.authenticateApiTokenWithCurrentUserRequest.message,
  emailAccountFilters: state.emailAccount.emailAccountsFilters,

  getTrulyInboxTokenRequestStatus:
    state.emailAccount.getTrulyInboxTokenRequest.status,
  sendTrulyInboxEmailAccountVerifyShApiKeyResponse:
    state.emailAccount.sendTrulyinboxEmailAccountVerifyShApiKeyResponse,
  trulyInboxToken: state.emailAccount.trulyInboxToken,
});

const mapDispatchToProps = {
  sendGetEmailAccountsRequest: (filters: EmailAccountsFilters) =>
    getEmailAccountsRequest(filters),
  sendTrulyinboxEmailAccountVerifyShApiKeyRequest: () =>
    trulyinboxEmailAccountVerifyShApiKeyRequest(),
  sendConnectEmailAccountRequest: (
    method: EmailAccountMethod,
    emailAccountId?: number,
  ) => connectEmailAccountRequest({ method, emailAccountId }),
  sendDisconnectEmailAccountRequest: (emailAccountId: number) =>
    disconnectEmailAccountRequest(emailAccountId),
  sendSetDefaultEmailAccountRequest: (emailAccountId: number) =>
    setDefaultEmailAccountRequest(emailAccountId),
  sendUpdateEmailAccountRequest: (
    emailAccountId: number,
    payload: UpdateEmailAccountRequestPayload,
  ) => updateEmailAccountRequest({ emailAccountId, ...payload }),
  sendDeleteEmailAccountRequest: (emailAccountId: number) =>
    deleteEmailAccountRequest(emailAccountId),
  handleAddEmailAccountModal: (showAddEmailAccountModal: boolean) =>
    updateApplicationState({ showAddEmailAccountModal }),
  sendGetConnectedUsersAndEmailAccountsRequest: () =>
    getConnectedUsersAndEmailAccountsRequest(),
  sendReactivateSubscriptionRequest: (
    payload: ReactivateResumeSubscriptionRequest,
  ) => reactivateSubscriptionRequest(payload),
  resetConnectedUsersAndEmailAccountsRequest: () =>
    resetConnectedUsersAndEmailAccountsRequest(),
  sendTestSMTPConnectionRequest: (payload: TestConnectionRequest) =>
    testSMTPConnectionRequest(payload),
  sendTestIMAPConnectionRequest: (payload: TestConnectionRequest) =>
    testIMAPConnectionRequest(payload),
  sendConnectSmtpImapAccountRequest: (payload: ConnectSmtpImapAccountRequest) =>
    connectSmtpImapAccountRequest(payload),
  sendGetSmtpImapAccountDetailsRequest: (emailAccountId: number) =>
    getSmtpImapAccountDetailsRequest(emailAccountId),
  sendUpdateSmtpImapAccountDetailsRequest: (
    emailAccountId: number,
    payload: UpdateSMTPIMAPAccountRequest,
  ) => updateSmtpImapAccountDetailsRequest({ emailAccountId, payload }),
  resetUpdateSmtpImapAccountDetails: () => resetUpdateSmtpImapAccountDetails(),
  resetSmtpImapTestConnection: () => resetSmtpImapTestConnection(),
  resetSmtpIMapConnectResponse: () => resetSmtpIMapConnectResponse(),
  resetReactivateSubscriptionResponse: () =>
    resetReactivateSubscriptionResponse(),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  updateProfileProgressRequest: (payload: UpdateProfileProgressPayload) =>
    updateProfileProgressRequest(payload),
  sendBulkEmailAccountUpdateRequest: (payload) =>
    bulkEmailUpdateRequest(payload),
  resetSelectedEmailAccounts: () => resetSelectedEmailAccount(),
  clearEmailAccountsSelection: () => clearEmailAccountsSelection(),
  sendAuthenticateApiTokenWithCurrentUserRequest: (apiToken: string) =>
    authenticateApiTokenWithCurrentUserRequest({ apiToken }),

  sendGetTrulyInboxTokenRequest: (tiUserId: number, email: string) =>
    getTrulyInboxTokenRequest({ tiUserId, email }),

  resetEmailAccountTagsRequest: () => resetEmailAccountTagsRequest(),
  updateEmailAccountFilters: (filters) => updateEmailAccountsFilters(filters),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    t: (x: string) => string;
    selectAllEmailAccounts: (payload) => void;
  };

export default withTranslation()(connector(EmailAccounts));
