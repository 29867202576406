import React from 'react';
import { InfoCircle } from '@saleshandy/icons';
import Switch, {
  Size,
} from '../../../../../../shared/design-system/components/atoms/switch';
import { ShAccountSettingsCode } from '../../../../enums/admin-settings';
import { OverlayTooltip } from '../../../../../../shared/design-system/components/overlay';
import Checkbox from '../../../../../../shared/design-system/components/atoms/checkbox';

type NestedSetting = {
  label: React.ReactNode;
  checked: boolean;
  onChange: () => void;
  disabled: boolean;
};

type IProps = {
  checked: boolean;
  onChange: (code: ShAccountSettingsCode) => void;
  label: string;
  code: ShAccountSettingsCode;
  learnMoreLink?: string;
  nestedSettings?: NestedSetting[];
};

const AdminSettingToggle: React.FC<IProps> = ({
  checked,
  onChange,
  label,
  code,
  learnMoreLink = '',
  nestedSettings = [],
}) => (
  <div className="admin-setting-toggle">
    <div className="d-flex align-items-center gap-4">
      <Switch
        className="admin-setting-toggle--switch"
        checked={checked}
        onChange={() => onChange(code)}
        size={Size.Small}
      />
      <div className="admin-setting-toggle--label">
        <span>{label}</span>
        {learnMoreLink && (
          <a href={learnMoreLink} target="_blank" rel="noreferrer">
            Learn More
          </a>
        )}
      </div>
      {code === ShAccountSettingsCode.IntegrationSetting && (
        <OverlayTooltip text="When this is turned ON, all events including member's events will be sent which might cause duplication of data.">
          <InfoCircle />
        </OverlayTooltip>
      )}
    </div>

    {nestedSettings && (
      <div className="admin-setting-toggle--nested">
        {nestedSettings.map((setting, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="admin-setting-toggle--nested-item"
          >
            <Checkbox
              id={`${index}`}
              checked={setting.checked}
              onChange={setting.onChange}
              label={setting.label}
              disabled={setting.disabled}
            />
          </div>
        ))}
      </div>
    )}
  </div>
);

export default AdminSettingToggle;
