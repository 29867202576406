export enum ResourceIdentifiers {
  SEQUENCES_READ = 'sequences/read',
  SEQUENCES_CREATE = 'sequences/create',
  SEQUENCES_CLONE = 'sequences/clone',
  SEQUENCES_RENAME = 'sequences/rename',
  SEQUENCES_DELETE = 'sequences/delete',
  SEQUENCES_PAUSE_RESUME = 'sequences/pause-resume',
  SEQUENCES_STEPS_CREATE = 'sequences/steps/create',
  SEQUENCES_STEPS_LIST = 'sequences/steps/list',
  SEQUENCES_STEPS_UPDATE = 'sequences/steps/update',
  SEQUENCES_STEPS_DELETE = 'sequences/steps/delete',
  SEQUENCES_STEPS_VARIANTS_CREATE = 'sequences/steps/variants/create',
  SEQUENCES_STEPS_VARIANTS_READ = 'sequences/steps/variants/read',
  SEQUENCES_STEPS_VARIANTS_UPDATE = 'sequences/steps/variants/update',
  SEQUENCES_STEPS_VARIANTS_DELETE = 'sequences/steps/variants/delete',
  SEQUENCES_SETTINGS_READ = 'sequences/settings/read',
  SEQUENCES_SETTINGS_UPDATE = 'sequences/settings/update',
  SEQUENCES_EMAILS_READ = 'sequences/emails/read',
  SEQUENCES_EMAILS_STATUS_UPDATE = 'sequences/emails/status/update',
  SEQUENCES_EMAILS_EXPORT = 'sequences/emails/export',
  SEQUENCES_PROSPECT_VERIFICATION_STATS_READ = 'sequences/prospect-verification-stats/read',
  SEQUENCES_CONTACTS_READ = 'sequences/contacts/read',
  SEQUENCES_CONTACTS_REMOVE = 'sequences/contacts/remove',
  SEQUENCES_VERIFY_PROSPECTS = 'sequences/verify-prospects',
  SEQUENCES_CONTACTS_COUNT = 'sequences/contacts/count',
  SEQUENCES_CONTACTS_IMPORT = 'sequences/contacts/import',
  SEQUENCES_PROSPECTS_EXPORT = 'sequences/prospects/export',
  SEQUENCES_SEND_TEST_MAIL = 'sequences/test-mail',
  CONTACTS_IMPORT = 'contacts/import',
  CONTACTS_CREATE = 'contacts/create',
  CONTACTS_VERIFY = 'contacts/verify',
  CONTACTS_DELETE = 'contacts/delete',
  CONTACTS_UNSUBSCRIBE = 'contacts/unsubscribe',
  CONTACTS_EXPORT = 'contacts/export',
  CONTACTS_READ = 'contacts/read',
  CONTACTS_COUNT = 'contacts/count',
  TAG_ASSIGN = 'tag/assign',
  TAG_UNASSIGN = 'tag/un-assign',
  TEMPLATES_READ = 'templates/read',
  TEMPLATES_SHARE = 'templates/share',
  TEMPLATES_UPDATE = 'templates/update',
  TEMPLATES_DELETE = 'templates/delete',
  TEMPLATES_CREATE = 'templates/create',
  TEMPLATES_CLONE = 'templates/clone',
  REPORTS_SEQUENCE_STATS = 'reports/sequence-stats',
  REPORTS_SEQUENCE_GRAPH = 'reports/sequence-graph',
  REPORTS_SEQUENCE_STATS_EXPORT = 'reports/sequence-stats/export',
  EMAIL_ACCOUNT_READ = 'email-account/read',
  EMAIL_ACCOUNT_DELETE = 'email-account/delete',
  EMAIL_ACCOUNT_TAGS = 'email-account/tags',
  EMAIL_ACCOUNT_CONNECT = 'email-account/connect',
  EMAIL_ACCOUNT_DISCONNECT = 'email-account/disconnect',
  EMAIL_ACCOUNT_UPDATE = 'email-account/update',
  EMAIL_ACCOUNT_HEALTH_SCORE_READ = 'email-account/email-health/read',
  EMAIL_ACCOUNT_HEALTH_SCORE_INITIATE = 'email-account/email-health/initiate',
  USER_SETTINGS_UPDATE = 'user-settings/update',
  SCHEDULES_READ = 'schedules/read',
  SCHEDULES_CREATE = 'schedules/create',
  SCHEDULES_UPDATE = 'schedules/update',
  SCHEDULES_DELETE = 'schedules/delete',
  TEAM_USER_INVITE = 'team/invite-user',
  TEAM_USER_DEACTIVATE = 'team/deactivate',
  TEAM_USER_REACTIVATE = 'team/reactivate',
  TEAM_USER_DELETE = 'team/delete',
  FIELDS_LIST = 'fields/list',
  FIELDS_CREATE = 'fields/create',
  FIELDS_UPDATE = 'fields/update',
  FIELDS_DELETE = 'fields/delete',
  API_TOKEN_READ = 'api-token/read',
  API_TOKEN_CREATE = 'api-token/create',
  API_TOKEN_DELETE = 'api-token/delete',
  CUSTOM_DOMAIN_CREATE = 'custom-domain/create',
  CUSTOM_DOMAIN_UPDATE = 'custom-domain/update',
  CUSTOM_DOMAIN_DELETE = 'custom-domain/delete',
  BLACKLIST_DOMAINS_ADD = 'blacklist-domains/add',
  BLACKLIST_DOMAINS_DELETE = 'blacklist-domains/delete',
  SUBSCRIPTION_MODIFY = 'subscription/modify',
  EV_CREDIT_READ = 'ev-credit/read',
  EV_CREDIT_PURCHASE = 'ev-credit/purchase',
  USER_PASSWORD_UPDATE = 'user/password/update',
  NOTIFICATION_MARK_AS_READ = 'notification/mark-as-read',
}
