import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@saleshandy/design-system';

import {
  OutOfOfficeContentProps,
  PauseForDays,
  PauseForDaysError,
} from '../types';
import { PauseProspectOption } from '../../../enums/out-of-office';
import {
  executeOnRequestStatus,
  getIsRequestPending,
  SubscriptionPlans,
} from '../../../../../shared/utils';

import Switch, {
  Size,
} from '../../../../../shared/design-system/components/atoms/switch';
import InputNumber from '../../../../../shared/design-system/components/input-number';
import RadioButtonGroup from '../../../../../shared/design-system/components/radio-button-group';

import OutOfOfficeHeader from './out-of-office-header';
import OutOfOfficeConfirmation from './out-of-office-confirmation';
import HeaderBanner from '../../header-banner';
import store from '../../../../../store';

const OutOfOfficeContent: React.FC<OutOfOfficeContentProps> = ({
  oooSettings,
  setOOOSettings,
  sendUpdateOOOSettingsRequest,
  updateOOOSettingsRequestStatus,
}) => {
  const isFilterDirty = useRef<boolean>(false);

  const [enableOutOfOffice, setEnableOutOfOffice] = useState(true);

  const [
    pauseProspectOption,
    setPauseProspectOption,
  ] = useState<PauseProspectOption>(
    PauseProspectOption.PauseProspectAsPerReturnDate,
  );

  const [
    pauseProspectsForDays,
    setPauseProspectsForDays,
  ] = useState<PauseForDays>({
    [PauseProspectOption.PauseProspectManually]: 14,
    [PauseProspectOption.PauseProspectAsPerReturnDate]: 14,
  });

  const [
    isPauseProspectsForDaysValid,
    setIsPauseProspectsForDaysValid,
  ] = useState<PauseForDaysError>({
    [PauseProspectOption.PauseProspectManually]: true,
    [PauseProspectOption.PauseProspectAsPerReturnDate]: true,
  });

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const onOutOfOfficeSettingToggled = (value: boolean) => {
    if (!value) {
      setShowConfirmationPopup(true);
    } else {
      setEnableOutOfOffice(value);
      sendUpdateOOOSettingsRequest({
        enableOutOfOffice: value,
        pauseProspectOption,
        pauseProspectForDays: pauseProspectsForDays[pauseProspectOption],
      });
      if (!isFilterDirty.current) {
        isFilterDirty.current = true;
      }
    }
  };

  const hideConfirmationPopup = () => {
    setShowConfirmationPopup(false);
  };

  const onOutOfOfficeSettingToggledOff = () => {
    setEnableOutOfOffice(false);
    hideConfirmationPopup();
    sendUpdateOOOSettingsRequest({
      enableOutOfOffice: false,
    });
    setOOOSettings(null);
    if (!isFilterDirty.current) {
      isFilterDirty.current = true;
    }
  };

  const onOutOfOfficeOptionChange = (value: PauseProspectOption) => {
    setPauseProspectOption(value);
    if (!isFilterDirty.current) {
      isFilterDirty.current = true;
    }
  };

  const isPauseProspectsForDaysInputValid = (
    value: string | number,
  ): boolean => {
    const number = parseFloat(value as string);

    if (
      // eslint-disable-next-line no-restricted-globals
      isNaN(number) ||
      !Number.isInteger(number) ||
      number < 1 ||
      number > 100
    ) {
      return false;
    }

    return true;
  };

  const onPauseForDaysChange = (key: PauseProspectOption, value: string) => {
    setPauseProspectsForDays({
      ...pauseProspectsForDays,
      [key]: value,
    });
    setIsPauseProspectsForDaysValid({
      ...isPauseProspectsForDaysValid,
      [key]: isPauseProspectsForDaysInputValid(value),
    });
    if (!isFilterDirty.current) {
      isFilterDirty.current = true;
    }
  };

  const onSave = () => {
    if (enableOutOfOffice) {
      if (
        isPauseProspectsForDaysInputValid(
          pauseProspectsForDays[pauseProspectOption],
        )
      ) {
        sendUpdateOOOSettingsRequest({
          enableOutOfOffice,
          pauseProspectOption,
          pauseProspectForDays: pauseProspectsForDays[pauseProspectOption],
        });
        setOOOSettings(null);
      }
    } else {
      sendUpdateOOOSettingsRequest({
        enableOutOfOffice,
      });
      setOOOSettings(null);
    }
  };

  useEffect(() => {
    if (oooSettings) {
      setEnableOutOfOffice(oooSettings.enableOutOfOffice);
      setPauseProspectOption(oooSettings.pauseProspectOption);
      setPauseProspectsForDays({
        ...pauseProspectsForDays,
        [oooSettings.pauseProspectOption]: oooSettings.pauseProspectForDays,
      });
    }
  }, [oooSettings]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateOOOSettingsRequestStatus,
      onSuccess: () => {
        isFilterDirty.current = false;
      },
    });
  }, [updateOOOSettingsRequestStatus]);

  const isUpdateOOOSettingsRequestPending = getIsRequestPending(
    updateOOOSettingsRequestStatus,
  );

  const {
    home: { subscription },
  } = store.getState();

  return (
    <div className="ooo--content">
      <OutOfOfficeHeader />
      {subscription?.planCode === SubscriptionPlans.FREE && <HeaderBanner />}

      <div className="ooo--setting">
        <div className="setting-toggle">
          <Switch
            checked={enableOutOfOffice}
            onChange={onOutOfOfficeSettingToggled}
            size={Size.Small}
          />
          <p>Enable Out-of-Office Workflow</p>
        </div>

        <div className="setting-options">
          <RadioButtonGroup
            name="ooo-settings-options"
            className="preset-list radio-vertical"
            value={pauseProspectOption}
            onChange={onOutOfOfficeOptionChange}
            options={[
              {
                value: PauseProspectOption.PauseProspectAsPerReturnDate,
                disabled: !enableOutOfOffice,
                label: (
                  <div className="setting-options-radio-label">
                    <p>
                      Pause prospects as per the return date found in
                      out-of-office email. If no date is found, pause them for
                    </p>
                    <InputNumber
                      disabled={
                        !enableOutOfOffice ||
                        pauseProspectOption !==
                          PauseProspectOption.PauseProspectAsPerReturnDate
                      }
                      value={
                        pauseProspectsForDays[
                          PauseProspectOption.PauseProspectAsPerReturnDate
                        ]
                      }
                      onChange={(value: string) =>
                        onPauseForDaysChange(
                          PauseProspectOption.PauseProspectAsPerReturnDate,
                          value,
                        )
                      }
                      variant={
                        !isPauseProspectsForDaysValid[
                          PauseProspectOption.PauseProspectAsPerReturnDate
                        ] && InputNumber.Variant.Error
                      }
                      min={1}
                      max={100}
                    />
                    <p>days</p>
                  </div>
                ),
              },
              {
                value: PauseProspectOption.PauseProspectManually,
                disabled: !enableOutOfOffice,
                label: (
                  <div className="setting-options-radio-label">
                    <p>Pause prospects for</p>
                    <InputNumber
                      disabled={
                        !enableOutOfOffice ||
                        pauseProspectOption !==
                          PauseProspectOption.PauseProspectManually
                      }
                      value={
                        pauseProspectsForDays[
                          PauseProspectOption.PauseProspectManually
                        ]
                      }
                      onChange={(value: string) =>
                        onPauseForDaysChange(
                          PauseProspectOption.PauseProspectManually,
                          value,
                        )
                      }
                      variant={
                        !isPauseProspectsForDaysValid[
                          PauseProspectOption.PauseProspectManually
                        ] && InputNumber.Variant.Error
                      }
                      min={1}
                      max={100}
                    />
                    <p>days when out-of-office reply is detected</p>
                  </div>
                ),
              },
            ]}
          />
        </div>

        <Button
          onClick={onSave}
          isLoading={isUpdateOOOSettingsRequestPending}
          disabled={
            isUpdateOOOSettingsRequestPending ||
            !isFilterDirty.current ||
            (enableOutOfOffice &&
              !isPauseProspectsForDaysValid[pauseProspectOption])
          }
          loadingText="Saving..."
        >
          Save
        </Button>

        <OutOfOfficeConfirmation
          showConfirmationPopup={showConfirmationPopup}
          hideConfirmationPopup={hideConfirmationPopup}
          onOutOfOfficeSettingToggledOff={onOutOfOfficeSettingToggledOff}
        />
      </div>
    </div>
  );
};

export default OutOfOfficeContent;
