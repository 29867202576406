import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../../../../../../store/root-reducer';
import AdminSettingExclusionInput from './admin-setting-exclusion-input';
import { ExclusionListEmailsAndDomains, PaginationOptions } from '../../types';
import {
  getExclusionListRequest,
  updateExclusionListRequest,
} from '../../extra-actions';
import { UpdateAdminSettingsExcludeList } from '../../../../types/request-payload';
import { resetUpdateExclusionRequestStatus } from '../../admin-settings-slice';

const mapStateToProps = (state: RootState) => ({
  updateExclusionListRequestStatus:
    state.adminSettings.updateExclusionListRequest.status,
  updateExclusionListRequestError:
    state.adminSettings.updateExclusionListRequest.error,
  updateExclusionListRequestMessage:
    state.adminSettings.updateExclusionListRequest.message,
  exclusionListPaginationOptions:
    state.adminSettings.exclusionListPaginationOptions,
  getExclusionListRequestStatus:
    state.adminSettings.getExclusionListRequest.status,
});

const mapDispatchToProps = {
  sendUpdateExclusionListRequest: (payload: UpdateAdminSettingsExcludeList) =>
    updateExclusionListRequest(payload),
  sendGetExclusionListRequest: (payload: PaginationOptions) =>
    getExclusionListRequest(payload),
  resetUpdateExclusionRequestStatus: () => resetUpdateExclusionRequestStatus(),
};
const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  exclusionList: ExclusionListEmailsAndDomains[];
  handleLoadMore: () => void;
};

export default connector(AdminSettingExclusionInput);
