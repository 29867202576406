import React from 'react';
import { accessibleOnClick } from '../../../../../utils/accessible-on-click';

function CommonProfileDropdownItem({ icon, label, onClick }) {
  return (
    <li className="list--dropdown-row" {...accessibleOnClick(onClick)}>
      <div className="list--dropdown-option">
        <div className="list--dropdown-icon">{icon}</div>
        <div className="list--dropdown-label">
          <p className="list--dropdown-label-text">{label}</p>
        </div>
      </div>
    </li>
  );
}

export default CommonProfileDropdownItem;
