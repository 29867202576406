import React, { useState } from 'react';
import { Button, EmptyList } from '@saleshandy/design-system';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import { NoUpcomingTrips } from '../../../../../../shared/svg';
import { supportUrls } from '../../../../../../shared/utils/urls';
import YoutubeVideoModal from '../../../../../../shared/design-system/components/molecules/youtube-video-modal';
import { ResourceIdentifiers } from '../../../../../../shared/utils/access-control/enums/resource-identifiers';
import hasResource from '../../../../../../shared/utils/access-control/has-resource';

type IProps = {
  onClick: VoidFunction;
};

const CustomDomainEmptyScreen: React.FC<IProps> = ({ onClick }) => {
  const [showYtModal, setShowYtModal] = useState(false);

  const showModalHandler = () => {
    setShowYtModal(true);
  };

  const hideModalHandler = () => {
    setShowYtModal(false);
  };

  return (
    <div className="custom-domain-empty-screen">
      <EmptyList>
        <EmptyList.Image>
          <NoUpcomingTrips />
        </EmptyList.Image>
        <EmptyList.Body>
          <EmptyList.Title>
            Add Custom Tracking Domain to Avoid Spam Filters
          </EmptyList.Title>
          <EmptyList.Description>
            Custom Tracking Domain adds a custom layer to your email tracking
            and it improves the sender's authenticity. Using a generic custom
            Tracking Domain will increase the risk of poor deliverability.
          </EmptyList.Description>
        </EmptyList.Body>
        <div className="shd-empty-list--footer">
          <Button
            variant="secondary"
            onClick={showModalHandler}
            disabled={!hasResource(ResourceIdentifiers.CUSTOM_DOMAIN_CREATE)}
          >
            Learn how to set
          </Button>
          <Button
            onClick={onClick}
            disabled={!hasResource(ResourceIdentifiers.CUSTOM_DOMAIN_CREATE)}
          >
            Add Custom Domain
          </Button>
        </div>
      </EmptyList>

      <YoutubeVideoModal
        show={showYtModal}
        onClose={hideModalHandler}
        titleContent="Learn how to set Custom Tracking Domain"
        youtubeVideoUrl={supportUrls.customTrackingDomainVideoGuide}
      />
    </div>
  );
};

export default CustomDomainEmptyScreen;
