import React, { useMemo, useState } from 'react';
import Tabs, { TabPane } from 'rc-tabs';

import type { IProps } from './tags-dropdown-container';
import { TagsDropdownTabs } from './types';

import {
  Overlay,
  OverlayPopover,
  Placement,
  TriggerType,
} from '../../design-system/components/overlay';
import { Schedule } from '../../../components/settings/types/schedule';
import MergeTagsContent from './components/merge-tags-content';
import VariableTagsContent from './components/variable-tags-content';
import { accessibleOnClick } from '../../utils/accessible-on-click';
import { supportUrls } from '../../utils/urls';

const TagsDropdown: React.FC<IProps> = ({
  mergeTags,
  onAddMergeTag,
  onAddVariableTag,

  children,
  disabled = false,
  placement = Placement.BottomEnd,
  hideVariableTags = [],
  disabledVariableTags = [],
  schedule,
  schedules,
  className = '',
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<TagsDropdownTabs>(
    TagsDropdownTabs.MergeTags,
  );
  const [showPopover, setShowPopover] = useState(false);
  const [sequenceSchedule, setSequenceSchedule] = useState<Schedule>(null);

  const onHide = () => {
    setShow(false);
  };

  const onTabChange = (key: string) => {
    setActiveTab(key as TagsDropdownTabs);
  };

  const renderTab = (
    label = '',
    shouldShowNewBadge = false,
    overlayPopoverText = '',
    overlayPopoverLink = '',
  ) => {
    const labelJSX = (
      <div>
        <span>{label}</span>
        {shouldShowNewBadge && <div className="new-badge">New</div>}
      </div>
    );

    if (overlayPopoverText) {
      return (
        <OverlayPopover
          show={showPopover}
          onToggle={setShowPopover}
          className="tags-tab-popover"
          trigger={TriggerType.Hover}
          placement={Placement.Bottom}
          content={
            <p>
              <span className="tags-tab-popover-text">
                {overlayPopoverText}
              </span>
              {overlayPopoverLink && (
                <span
                  className="tags-tab-popover-link"
                  {...accessibleOnClick(() => {
                    window.open(overlayPopoverLink, '_blank');
                  })}
                >
                  Learn More
                </span>
              )}
            </p>
          }
        >
          {labelJSX}
        </OverlayPopover>
      );
    }

    return labelJSX;
  };

  useMemo(() => {
    if (schedule?.id && !sequenceSchedule) {
      const seqScheduled = schedules.find((s) => s.id === schedule?.id);
      if (seqScheduled) {
        setSequenceSchedule({
          ...seqScheduled,
          ...(seqScheduled?.timeSlots && {
            timeSlots: JSON.parse(seqScheduled.timeSlots),
          }),
        });
      }
    }
  }, [schedule, schedules]);

  return (
    <Overlay
      show={show}
      onToggle={(value) => {
        if (!disabled) {
          setShow(value);
        }
        if (!value) {
          setActiveTab(TagsDropdownTabs.MergeTags);
        }
      }}
      trigger={TriggerType.Click}
      rootClose={true}
      placement={placement}
      overlay={
        <div
          className={`transfer-overlay merge-variable-tags--overlay ${className}`}
        >
          <div className="transfer-container merge-variable-tags--container">
            <Tabs
              activeKey={activeTab}
              prefixCls="bs-tabs"
              className="bs-tabs-small merge-variable-tags--tabs"
              onChange={onTabChange}
            >
              <TabPane
                tab={renderTab('Merge Tags')}
                key={TagsDropdownTabs.MergeTags}
              >
                <MergeTagsContent
                  mergeTags={mergeTags}
                  onAddMergeTag={onAddMergeTag}
                  onHide={onHide}
                />
              </TabPane>
              <TabPane
                tab={renderTab(
                  'Variable Tags',
                  true,
                  'Dynamic placeholders that automatically insert relevant information into emails.',
                  supportUrls.variableTagsBlogURL,
                )}
                key={TagsDropdownTabs.VariableTags}
              >
                <VariableTagsContent
                  onAddVariableTag={onAddVariableTag}
                  onHide={onHide}
                  hideVariableTags={hideVariableTags}
                  disabledVariableTags={disabledVariableTags}
                  sequenceSchedule={sequenceSchedule}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      }
    >
      {children}
    </Overlay>
  );
};

export default TagsDropdown;
