import React, { useRef, useState } from 'react';
import validator from 'validator';
import classNames from 'classnames';
import { Pills, SkeletonLoading } from '@saleshandy/design-system';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';

type IProps = {
  emailsAndDomains: string[];
  setEmailsAndDomains: (emailsAndDomains: string[]) => void;
  isError: boolean;
  setIsError: (value: boolean) => void;
  isMaxedEmailAdded: boolean;
  hasMore?: boolean;
  onLoadMore?: () => void;
  loadMoreLabel?: string;
  isLoading?: boolean;
  placeholder?: string;
};
const EmailsAndDomainsInput: React.FC<IProps> = ({
  emailsAndDomains,
  setEmailsAndDomains,
  isError,
  setIsError,
  isMaxedEmailAdded,
  hasMore = false,
  onLoadMore,
  loadMoreLabel = '',
  isLoading = false,
  placeholder = 'Add entries with comma, semicolon, space or line breaks',
}) => {
  // https://regex101.com/r/YlyqJk/1 => Regular Expression working example
  const domainRegex = /^\s*(?:(?:\w+(?:-+\w+)*\.)+[a-z,A-Z]+)\s*(?:,\s*(?:(?:\w+(?:-+\w+)*\.)+[a-z,A-Z]+)\s*)*$/;

  const emailsAndDomainsInputRef = useRef<HTMLInputElement>(null);

  const [emailsAndDomainsValue, setEmailsAndDomainsValue] = useState<string>(
    '',
  );

  const onContainerClicked = () => {
    if (emailsAndDomainsInputRef?.current) {
      emailsAndDomainsInputRef.current.focus();
    }
  };

  const onEmailAndDomainValueChange = (value: string) => {
    if (value !== ',' && value !== ' ' && value !== ';') {
      setEmailsAndDomainsValue(value);
    }
  };

  const onEmailsAndDomainsRemove = (value: string) => {
    setEmailsAndDomains(emailsAndDomains.filter((email) => email !== value));
  };

  const onAddEmailsAndDomains = () => {
    const addedEmailsAndDomains = emailsAndDomainsValue
      .replaceAll(',', ' ')
      .replaceAll(';', ' ')
      .split(' ')
      .filter((e) => e.trim());

    const emailsAndDomainRef = [];
    let emailsAndDomainValues = '';
    let isErrorRef = false;

    addedEmailsAndDomains.forEach((v, index) => {
      const value = v.toLowerCase();
      if (
        (validator.isEmail(value) || domainRegex.test(value)) &&
        !emailsAndDomains.includes(value) &&
        !emailsAndDomainRef.includes(value)
      ) {
        emailsAndDomainRef.push(value);
      } else if (index === addedEmailsAndDomains.length - 1) {
        emailsAndDomainValues = emailsAndDomainValues.concat(`${value}`);
      } else {
        emailsAndDomainValues = emailsAndDomainValues.concat(`${value}, `);
      }
      isErrorRef = isErrorRef || !!emailsAndDomainValues;
    });
    setIsError(isErrorRef);
    setEmailsAndDomainsValue(emailsAndDomainValues);
    setEmailsAndDomains([...emailsAndDomains, ...emailsAndDomainRef]);
  };

  const onAction = (action: string) => {
    if (
      action === ',' ||
      action === ' ' ||
      action === ';' ||
      action === 'Enter' ||
      action === 'Blur' ||
      action === 'Paste'
    ) {
      onAddEmailsAndDomains();
    }
  };

  const onKeyPress = (e) => {
    onAction(e.key);
  };

  const onKeyDown = (e) => {
    if (
      e.key === 'Backspace' &&
      emailsAndDomainsValue === '' &&
      emailsAndDomains.length > 0
    ) {
      onEmailsAndDomainsRemove(emailsAndDomains[emailsAndDomains.length - 1]);
    }
  };

  const onPaste = () => {
    onAction('Paste');
  };
  const onBlur = () => {
    onAction('Blur');
  };

  const classes = classNames([
    'emails-and-domains-input--container',
    {
      error: isMaxedEmailAdded || isError,
    },
  ]);

  return (
    <>
      <div className={classes} {...accessibleOnClick(onContainerClicked)}>
        <div className="emails-and-domains-input--pills">
          {emailsAndDomains?.map(
            (value) =>
              value && (
                <Pills
                  theme="outline"
                  size="md"
                  label={value}
                  showCloseIcon={true}
                  onClose={() => onEmailsAndDomainsRemove(value)}
                  key={value}
                />
              ),
          )}

          {hasMore &&
            (isLoading ? null : (
              <Pills
                theme="outline"
                size="md"
                label={loadMoreLabel}
                onClick={() => {
                  onLoadMore?.();
                }}
                showCloseIcon={false}
                key="hasMore"
                className="cursor-pointer"
              />
            ))}

          {isLoading && (
            <div className="emails-and-domains-input--loader">
              <SkeletonLoading width={100} height={24} />
              <SkeletonLoading width={110} height={24} />
              <SkeletonLoading width={105} height={24} />
              <SkeletonLoading width={115} height={24} />
              <SkeletonLoading width={120} height={24} />
            </div>
          )}

          <input
            className="emails-and-domains-input--input"
            value={emailsAndDomainsValue}
            placeholder={placeholder}
            onChange={(e) => onEmailAndDomainValueChange(e?.target?.value)}
            ref={emailsAndDomainsInputRef}
            onKeyPress={onKeyPress}
            onPaste={onPaste}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
          />
        </div>
      </div>
      <div className="emails-and-domains-input--helper-wrapper">
        <span className="emails-and-domains-input--error-msg">
          {isMaxedEmailAdded && 'You can enter up to 1000 items here'}
          {!isMaxedEmailAdded && isError && 'Invalid entrie(s) found'}
        </span>
      </div>
    </>
  );
};

export default EmailsAndDomainsInput;
