/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ArrowUpRight } from '@saleshandy/icons';
import { SkeletonLoading } from '@saleshandy/design-system';
import { useTranslation } from 'react-i18next';
import { isValueBasedPlan } from '../../../../../../components/settings/components/billing-subscription/components/outreach-plans/utils/helper';
import { SubscriptionPlans } from '../../../../../utils/subscription-plans';
import { getCoreFeaturesCreditsRequest } from '../../../../../../components/home/extra-actions';
import { constants } from '../../../../../enums/constants';
import { executeOnRequestStatus } from '../../../../../utils';
import { useCreditsSelectors } from './credits-box-helper';
import { resetCoreFeaturesCreditsRequestStatus } from '../../../../../../components/home/home-slice';

function CreditsBox({ dropdownIsOpen }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [hoveredCredit, setHoveredCredit] = useState<string | null>(null);
  const [loading, setLoading] = useState(true); // Loading state to control skeleton visibility

  const {
    planCode,
    planType,
    leadFinderCreditsAvailable,
    emailSendAvailable,
    emailSendTotal,
    prospectAddAvailable,
    prospectAddTotal,
    emailVerificationAvailable,
    getCoreFeaturesCreditsRequestStatus,
  } = useCreditsSelectors();

  useEffect(() => {
    executeOnRequestStatus({
      status: getCoreFeaturesCreditsRequestStatus,
      onSuccess: () => {
        setLoading(false);
      },
      onFailed: () => {
        setLoading(false);
      },
      onPending: () => {
        setLoading(true);
      },
    });
  }, [getCoreFeaturesCreditsRequestStatus, dropdownIsOpen]);
  useEffect(() => {
    if (dropdownIsOpen) {
      // Reset the status to 'ideal' to allow re-fetching
      dispatch(resetCoreFeaturesCreditsRequestStatus());

      // Now dispatch the request to fetch data
      dispatch(getCoreFeaturesCreditsRequest());
    }
  }, [dropdownIsOpen, dispatch]);

  const creditsData = [
    {
      title: t('labels.lead_finder_credits'),
      available: parseInt(leadFinderCreditsAvailable, 10) || 0,
      path: '/settings/billing/subscriptions/lead-finder',
    },
    {
      title: t('labels.email_verification'),
      available: parseInt(emailVerificationAvailable, 10) || 0,
      path: '/settings/billing/subscriptions/ev-credits',
    },
  ];

  const additionalCreditsData = [
    {
      title: t('labels.monthly_emails_sent'),
      available: parseInt(emailSendAvailable, 10) || 0,
      total: parseInt(emailSendTotal, 10) || 0,
    },
    {
      title: t('labels.total_prospects'),
      available: parseInt(prospectAddAvailable, 10) || 0,
      total: parseInt(prospectAddTotal, 10) || 0,
    },
  ];

  const calculateRemainingPercentage = (
    available: number,
    total: number,
  ): number => {
    if (!total || total <= 0) return 0; // Avoid division by zero or negative totals
    return Math.round((available / total) * 100);
  };

  const getRemainingColor = (available, title) => {
    if (
      (title === t('labels.lead_finder_credits') &&
        available < constants.LEAD_REVEAL_BOTTOM_LIMIT) ||
      (title === t('labels.email_verification') &&
        available < constants.EMAIL_VERIFICATION_BOTTOM_LIMIT)
    ) {
      return 'credit-available-red';
    }
    return 'credit-available-normal';
  };

  const handleMouseEnter = (title) => {
    setHoveredCredit(title);
  };

  const handleMouseLeave = () => {
    setHoveredCredit(null);
  };

  const isBelowThreshold = (available: number, title: string): boolean => {
    const leadFinderThreshold = constants.LEAD_REVEAL_BOTTOM_LIMIT;
    const emailVerificationThreshold =
      constants.EMAIL_VERIFICATION_BOTTOM_LIMIT;

    return (
      (title === t('labels.lead_finder_credits') &&
        available < leadFinderThreshold) ||
      (title === t('labels.email_verification') &&
        available < emailVerificationThreshold)
    );
  };

  return (
    <div className="credits-box">
      <div className="section-without-progress-bar">
        {creditsData.map((credit) => (
          <div
            // className="feature-without-progress-bar"
            className={`feature-without-progress-bar ${
              credit.title === 'Lead Finder Credits' ? 'lead-finder-margin' : ''
            }`}
            key={credit.title}
            onMouseEnter={() => handleMouseEnter(credit.title)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="credit-available-option">
              <span className="feature-credit-title">{credit.title}</span>
              <span
                className={`${getRemainingColor(
                  credit.available,
                  credit.title,
                )}`}
              >
                {loading ? (
                  <SkeletonLoading height={16} width={104} />
                ) : hoveredCredit === credit.title &&
                  isBelowThreshold(credit.available, credit.title) ? (
                  <div className="add-credits-link">
                    <a href={credit.path} className="addcredits-link">
                      Add Credits <ArrowUpRight />
                    </a>
                  </div>
                ) : (
                  credit.available.toLocaleString()
                )}
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Conditional Divider and Additional Credits */}
      {(isValueBasedPlan(planType) ||
        planCode === SubscriptionPlans.TRIAL ||
        planCode === SubscriptionPlans.FREE) && (
        <>
          <div className="divider-inside-credit-box" />
          <div className="section-with-progress-bar">
            {additionalCreditsData.map((credit) => {
              const creditsUsedPercentage =
                100 -
                calculateRemainingPercentage(credit.available, credit.total);
              return (
                <div
                  className="feature-with-progress-bar"
                  key={credit.title + creditsData.length}
                  onMouseEnter={() => handleMouseEnter(credit.title)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="totalandused-credit-option">
                    <span className="feature-credit-title">{credit.title}</span>
                    <span className="number-link-part">
                      {loading ? (
                        <SkeletonLoading height={16} width={104} />
                      ) : hoveredCredit === credit.title &&
                        creditsUsedPercentage >= 85 ? (
                        <div className="upgrade-link-feature-dropdown">
                          <a
                            href="/settings/billing/subscriptions/email-outreach"
                            className="upgrade-link-dropdown"
                          >
                            Upgrade <ArrowUpRight />
                          </a>
                        </div>
                      ) : (
                        <>
                          <div className="number-part-whole">
                            <span className="credit-used-number">
                              {(
                                credit.total - credit.available
                              ).toLocaleString()}
                            </span>{' '}
                            <span className="class-for-slash">
                              &nbsp;/&nbsp;
                            </span>
                            <span className="credit-feature-total">
                              {credit.total.toLocaleString()}
                            </span>
                          </div>
                        </>
                      )}
                    </span>
                  </div>
                  {loading ? (
                    <SkeletonLoading width={272} height={6} />
                  ) : (
                    <div className="progress-bar ">
                      <div
                        className={`progress-fill  ${
                          creditsUsedPercentage >= 85 ? 'warning' : 'normal'
                        }`}
                        style={{ width: `${creditsUsedPercentage}%` }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default CreditsBox;
