import { generateMultiLevelSelectOptions } from '../../../../../shared/utils/get-verification-sub-status-mapping';
import { isAgencyUser } from '../../../../../shared/utils/user-details';
import {
  ContactStatusType,
  ContactStatusTypeDisplayName,
  ContactVerificationStatus,
  ContactVerificationStatusTypeDisplayName,
  CreationSourceDisplayName,
  ProspectCreationSource,
} from '../../../../sequence/enums';

import {
  ProspectFilterField,
  FilterSource,
  ProspectFiltersFieldsAttributesValue,
} from '../types';
// eslint-disable-next-line import/no-cycle
import { prospectFiltersFieldsKeysFilterOptions } from './helpers';

export const staticDropdownKeysForProspectFilter = [
  'verificationStatus',
  'verificationSubStatus',
  'freeEmailAccount',
  'creationSource',
  'status',
];

const getStaticDropdownForProspectFilter = (key) => {
  if (key === 'verificationStatus') {
    return [
      {
        key: ContactVerificationStatus.Valid,
        name: ContactVerificationStatusTypeDisplayName.Valid,
      },
      {
        key: ContactVerificationStatus.Risky,
        name: ContactVerificationStatusTypeDisplayName.Risky,
      },
      {
        key: ContactVerificationStatus.Bad,
        name: ContactVerificationStatusTypeDisplayName.Bad,
      },
      {
        key: ContactVerificationStatus.Unverified,
        name: ContactVerificationStatusTypeDisplayName.Unverified,
      },
    ];
  }
  if (key === 'verificationSubStatus') {
    return generateMultiLevelSelectOptions();
  }

  if (key === 'creationSource') {
    return [
      {
        key: ProspectCreationSource.manually_created,
        name: CreationSourceDisplayName.Manually_Created,
      },
      {
        key: ProspectCreationSource.csv_import,
        name: CreationSourceDisplayName.CSV_Import,
      },
      {
        key: ProspectCreationSource.lead_finder,
        name: CreationSourceDisplayName.Lead_Finder,
      },
      {
        key: ProspectCreationSource.api,
        name: CreationSourceDisplayName.API,
      },
      {
        key: ProspectCreationSource.chrome_extension,
        name: CreationSourceDisplayName.Chrome_Extention,
      },
      {
        key: ProspectCreationSource.unified_inbox,
        name: CreationSourceDisplayName.Unified_Inbox,
      },
    ];
  }

  if (key === 'status') {
    return [
      {
        key: ContactStatusType.Active,
        name: ContactStatusTypeDisplayName.active,
      },
      {
        key: ContactStatusType.Paused,
        name: ContactStatusTypeDisplayName.paused,
      },
      {
        key: ContactStatusType.Replied,
        name: ContactStatusTypeDisplayName.replied,
      },
      {
        key: ContactStatusType.Bounced,
        name: ContactStatusTypeDisplayName.bounced,
      },
      {
        key: ContactStatusType.Unsubscribed,
        name: ContactStatusTypeDisplayName.unsubscribed,
      },
    ];
  }

  return [];
};

export const getProspectFilterFieldAttributeValues = (
  field: ProspectFilterField,
  value: (string | number)[],
  selectedValues: any[],
  prospectFiltersFieldsAttributesValue: ProspectFiltersFieldsAttributesValue,
) => {
  if (staticDropdownKeysForProspectFilter.includes(field.key)) {
    return {
      key: field.key,
      meta: null,
      options: getStaticDropdownForProspectFilter(field.key),
    };
  }

  let keyValue = field.key;
  let fieldValue = prospectFiltersFieldsAttributesValue?.[field.key];

  if (field.source === FilterSource.FIELD) {
    keyValue = field.id;
    fieldValue = prospectFiltersFieldsAttributesValue?.[field.id];
  }

  const meta = fieldValue?.meta || null;
  const options =
    fieldValue?.payload?.map((item) => ({
      key: item.value,
      ...item,
    })) || [];

  const shouldFetch = options.length === 0;
  const sendValuesInPayload = [
    'sequence',
    'clientSequenceAssociation',
    'fileName',
  ].includes(keyValue);

  let doNotUpdateOptions = prospectFiltersFieldsKeysFilterOptions.includes(
    keyValue,
  );

  if (keyValue === 'owners' && isAgencyUser()) {
    doNotUpdateOptions = false;
  }

  const updatedOptions = doNotUpdateOptions
    ? options
    : [
        ...(sendValuesInPayload
          ? selectedValues.filter(
              (item) =>
                !options.some(
                  (option) =>
                    option.value.toString() === item?.value?.toString(),
                ),
            )
          : value
              .filter(
                (val) =>
                  !options.some(
                    (option) => option.value.toString() === val?.toString(),
                  ),
              )
              .map((val) => ({
                key: val,
                name: val,
                value: val,
              }))),
        ...options,
      ];

  return {
    key: keyValue,
    shouldFetch,
    sendValuesInPayload,
    meta,
    options: updatedOptions,
  };
};
