import React from 'react';
import classnames from 'classnames';
import { SkeletonLoading } from '@saleshandy/design-system';
import { IProps } from './types';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';

const EvCreditPlans: React.FC<IProps> = ({
  credits = [],
  onSelectPlan,
  selectedPlan,
  remainingCredit,
  isPlansLoading = false,
}) => {
  const handleSelectPlan = (plan) => {
    if (remainingCredit > plan.credits) {
      return;
    }
    onSelectPlan(plan);
  };

  const renderCreditPlans = () => {
    if (isPlansLoading || credits?.length === 0) {
      const arr = Array.from({ length: 8 }, (_, i) => i + 1);
      return (
        <div className="credit-plans-container">
          {arr.map((key) => (
            <div key={key} className="credit-plan loading">
              <SkeletonLoading width={44} height={20} />
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="credit-plans-container">
        {credits?.map((credit) => {
          const isSelected = credit.id === selectedPlan?.id;
          const creditPlanClass = classnames('credit-plan', {
            selected: isSelected,
            disabled: remainingCredit > credit.credits,
          });

          return (
            <div
              key={credit.id}
              className={creditPlanClass}
              {...accessibleOnClick(() => handleSelectPlan(credit))}
            >
              {credit.isBestValue === 1 && (
                <div className="best-value-badge">Best Value</div>
              )}
              <p className="plan-name">{credit.name.replace(' Credits', '')}</p>
            </div>
          );
        })}
      </div>
    );
  };

  return <>{renderCreditPlans()}</>;
};

export default EvCreditPlans;
