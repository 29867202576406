import api from '../../../api';
import { ResponseSuccess } from '../../../shared/types';
import {
  ProspectImportEventPayload,
  SubscribedEventPayload,
} from '../components/api-tokens/types';

export const getApiTokenList = async () =>
  api.get<ResponseSuccess>(`/api-token/`);

export const createApiToken = async (label: string) =>
  api.post<ResponseSuccess>(`/api-token`, { label });

export const revokeApiToken = async (tokenId: number) =>
  api.delete<ResponseSuccess>(`/api-token/${tokenId}`);

export const getCobaltSessionToken = async () =>
  api.get<ResponseSuccess>(`/integration/session-token`);

export const cobaltEventsSubscribe = async (
  payload: SubscribedEventPayload[],
) => api.post<ResponseSuccess>(`/integration/event-subscribe`, payload);

export const cobaltProspectImportSubscribe = async (
  payload: ProspectImportEventPayload,
) => api.post<ResponseSuccess>(`/integration/import-prospect`, payload);

export const getIntegrationAdminSetting = async () =>
  api.get<ResponseSuccess>(`/integration/admin-setting`);

export const changeIntegrationSetting = async (isAllUser: boolean) =>
  api.patch<ResponseSuccess>(`/integration/event-recipient-type`, {
    isAllUser,
  });

export const generateIntegrationApiToken = async () =>
  api.patch<ResponseSuccess>(`/api-token/generate-integration-api-key`);

export const authenticateApiTokenWithCurrentUser = async (apiToken: string) =>
  api.get<ResponseSuccess>(
    `/api-token/authenticate-api-token?apiToken=${apiToken}`,
  );
