/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable no-case-declarations */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Children, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
  CircleMinus,
  CirclePlus,
  InfoCircle,
  Minus,
  Plus,
} from '@saleshandy/icons';
import { useLocation } from 'react-router-dom';
import Checkbox from '../../../../shared/design-system/components/atoms/checkbox';
import {
  OverlayTooltip,
  Placement,
} from '../../../../shared/design-system/components/overlay';
import Tag from '../../../../shared/design-system/components/tag';
import {
  deepClone,
  findParentKey,
  getExceptFilterValues,
  getFilterKeys,
  getFilterValuesWithExtraValues,
} from '../../helpers/helper';
import { FilterComponentType } from '../../type';
import useFirstEffect from '../../../../shared/hooks/useFirstEffect';
import { getFilterSuggestionAPI } from '../../helpers/leads.api';
import Icon from '../../../../shared/design-system/components/atoms/icon/icon';
import { getLabel } from '../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/contact-list-content/components/prospect-activity-modal/helpers';

// Keys Mapped with backend keys
const MAPPED_KEYS = {
  location: 'location',
  skills: 'skill',
  school: 'school',
  jobTitle: 'job-title',
  industry: 'industry',
  department: 'department',
};

const NOT_ALLOWED_APPEND_CHECKBOXES = ['managementLevels'];

const RenderAutocomplete = ({
  suggestions,
  onSelectOption,
  filterKey,
  selectedSuggestionIndex,
  handleOnChange,
  setShowAutoComplete,
}: {
  suggestions: string[];
  onSelectOption: (option: string) => void;
  filterKey: string;
  selectedSuggestionIndex: number;
  handleOnChange: ({}: {
    action: string;
    groupName: string;
    childName?: string;
    exclude?: boolean;
  }) => void;
  setShowAutoComplete: (value: boolean) => void;
}) => {
  if (suggestions?.length === 0) {
    return null;
  }

  return (
    <ul
      className={`autocomplete ${
        ['school', 'jobTitle'].includes(filterKey)
          ? 'autocomplete-custome-menu'
          : ''
      }`}
    >
      {suggestions?.map((suggestion, index) => (
        <li
          key={suggestion}
          onClick={() => onSelectOption(suggestion)}
          className={
            index === selectedSuggestionIndex
              ? 'autocomplete-menu-list'
              : 'item d-flex align-items-center position-relative justify-content-between'
          }
        >
          <p className="autocomplete-text">{suggestion}</p>
          <div className="include-exclude-icon">
            <OverlayTrigger
              placement={Placement.Bottom}
              overlay={
                <Tooltip
                  id={`tooltip-${suggestion}-include-child-${suggestion}`}
                  className="bs-tooltip-inner"
                >
                  Include
                </Tooltip>
              }
            >
              <CirclePlus
                className="circle-plus include-circle-plus"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnChange({
                    action: 'inputOption',
                    groupName: suggestion,
                  });
                  setShowAutoComplete(false);
                }}
              />
            </OverlayTrigger>

            <OverlayTrigger
              placement={Placement.Bottom}
              overlay={
                <Tooltip
                  id={`tooltip-${suggestion}-include-child-${suggestion}`}
                  className="bs-tooltip-inner"
                >
                  Exclude
                </Tooltip>
              }
            >
              <CircleMinus
                className="circle-minus"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnChange({
                    action: 'excludeOption',
                    groupName: suggestion,
                  });
                  setShowAutoComplete(false);
                }}
              />
            </OverlayTrigger>
          </div>
        </li>
      ))}
    </ul>
  );
};
const getLebelTooltip = (text: string) => (
  <Tooltip id="popover-basic" className="bs-tooltip-inner">
    {text}
  </Tooltip>
);

const RenderCheckboxGroup = ({
  label,
  name,
  checked,
  children,
  expanded,
  handleOnChange,
  filterKey,
  setShowLabelToolTip,
  showLabelToolTip,
  infoCircleToolTip,
  hideIncludeExclude = false,
}) => (
  <>
    <div
      className={`checkbox-number ${checked === false ? 'not-selected' : ''}`}
    >
      <Checkbox
        checked={checked === true}
        value={name}
        onChange={() => {
          handleOnChange({ action: 'changeParent', groupName: name });
        }}
        label={
          <div
            className="label-container"
            onMouseEnter={() => setShowLabelToolTip(label)}
            onMouseLeave={() => setShowLabelToolTip(null)}
          >
            <OverlayTrigger
              placement={Placement.Bottom}
              overlay={getLebelTooltip(label)}
              show={showLabelToolTip === label && label?.length > 22}
            >
              <p className="checkbox-lable">
                {label.length > 22 ? `${label?.substring(0, 22)}..` : label}
              </p>
            </OverlayTrigger>

            <span className="checked-number">
              {children?.length > 0 && `(${children?.length})`}
            </span>
            {infoCircleToolTip ? (
              <OverlayTooltip
                placement={Placement.Bottom}
                text={infoCircleToolTip}
              >
                <InfoCircle
                  width={13.33}
                  height={13.33}
                  className="ml-1 mt-1 gray-txt-11"
                />
              </OverlayTooltip>
            ) : null}
          </div>
        }
        intermediate={checked === 'indeterminate'}
      />
      {!hideIncludeExclude ? (
        <div className="include-exclude-icon">
          <OverlayTrigger
            placement={Placement.Bottom}
            overlay={
              <Tooltip
                id={`tooltip-${name}-include`}
                className="bs-tooltip-inner"
              >
                Include
              </Tooltip>
            }
          >
            <CirclePlus
              className="circle-plus include-circle-plus"
              onClick={() =>
                handleOnChange({ action: 'includeParent', groupName: name })
              }
            />
          </OverlayTrigger>

          <OverlayTrigger
            placement={Placement.Bottom}
            overlay={
              <Tooltip
                id={`tooltip-${name}-include`}
                className="bs-tooltip-inner"
              >
                Exclude
              </Tooltip>
            }
          >
            <CircleMinus
              className="circle-minus"
              onClick={() =>
                handleOnChange({ action: 'excludeParent', groupName: name })
              }
            />
          </OverlayTrigger>
        </div>
      ) : null}
    </div>
    <div>
      {expanded?.includes(name) && (
        <div>
          {children?.map((childItem, index) => (
            <div
              className={`d-flex justify-content-between align-items-start checkbox-number checkbox-number-child ${
                childItem?.checked === false ? 'not-selected' : ''
              }`}
              // eslint-disable-next-line react/no-array-index-key
              key={`${filterKey}-${name}-child-${index}`}
            >
              <Checkbox
                key={childItem?.key}
                value={childItem?.key}
                onChange={() =>
                  handleOnChange({
                    action: 'changeChild',
                    groupName: name,
                    childName: childItem?.key,
                  })
                }
                label={
                  <span
                    onMouseEnter={() => setShowLabelToolTip(childItem?.value)}
                    onMouseLeave={() => setShowLabelToolTip(null)}
                  >
                    <OverlayTrigger
                      placement={Placement.Bottom}
                      overlay={getLebelTooltip(childItem?.value)}
                      show={
                        showLabelToolTip === childItem?.value &&
                        childItem?.value?.length > 21
                      }
                    >
                      <span>
                        {childItem?.value?.length > 21
                          ? `${childItem?.value?.substring(0, 21)}..`
                          : childItem?.value}
                      </span>
                    </OverlayTrigger>
                  </span>
                }
                checked={childItem?.checked}
              />
              <div className="include-exclude-icon">
                <OverlayTrigger
                  placement={Placement.Bottom}
                  overlay={
                    <Tooltip
                      id={`tooltip-${name}-include-child-${childItem?.key}`}
                      className="bs-tooltip-inner"
                    >
                      Include
                    </Tooltip>
                  }
                >
                  <CirclePlus
                    className="circle-plus include-circle-plus"
                    onClick={() => {
                      handleOnChange({
                        action: 'includeChild',
                        groupName: name,
                        childName: childItem?.key,
                      });
                    }}
                  />
                </OverlayTrigger>

                <OverlayTrigger
                  placement={Placement.Bottom}
                  overlay={
                    <Tooltip
                      id={`tooltip-${name}-include-child-${childItem?.key}`}
                      className="bs-tooltip-inner"
                    >
                      Exclude
                    </Tooltip>
                  }
                >
                  <CircleMinus
                    className="circle-minus"
                    onClick={() => {
                      handleOnChange({
                        action: 'excludeChild',
                        groupName: name,
                        childName: childItem?.key,
                      });
                    }}
                  />
                </OverlayTrigger>
              </div>
              {/* )} */}
            </div>
          ))}
        </div>
      )}
    </div>
  </>
);

const FilterCheckbox = ({
  list = [],
  filterKey,
  excludeKey,
  inputClassName = '',
  inputPlaceHolder = '',
  inputLabel = '',
  hideSearchBox = false,
  handleQuery,
  isExpand = false,
  parentKey = '',
  removeTopMargin = false,
  infoCircleToolTipMapping = {},
  hideIncludeExclude = false,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const inputRef = useRef(null);

  const handleInitializeCheckboxes = (mainCheckbox: boolean) => {
    let initCheckboxes = list || [];
    // if (initCheckboxes?.length) {
    initCheckboxes = list.map((item) => {
      let children = [];
      if (item?.children?.length > 0) {
        children = item.children.map((child) => ({
          ...child,
          checked:
            (queryParams
              ?.getAll(filterKey)
              ?.map((item) => item?.replace(/"/g, ''))
              ?.includes(child?.key) ||
              queryParams?.getAll(excludeKey)?.includes(child?.key) ||
              !!(
                queryParams
                  ?.getAll(filterKey)
                  ?.map((item) => item?.replace(/"/g, ''))
                  ?.includes(item?.key) ||
                queryParams
                  ?.getAll(excludeKey)
                  ?.map((item) => item?.replace(/"/g, ''))
                  ?.includes(item?.key)
              )) ??
            false,
          isExcluded:
            queryParams
              ?.getAll(excludeKey)
              ?.map((item) => item?.replace(/"/g, ''))
              ?.includes(child?.key) ?? true,
        }));
      }

      const childrenCheckedCount = children?.filter((c) => c?.checked)?.length;
      const isIntermediate = !!(
        childrenCheckedCount >= 1 &&
        item?.children?.length !== childrenCheckedCount
      );
      return {
        ...item,
        checked: isIntermediate
          ? 'indeterminate'
          : !!(
              queryParams
                ?.getAll(filterKey)
                .map((item) => item?.replace(/"/g, ''))
                ?.includes(item?.key) ||
              queryParams
                ?.getAll(excludeKey)
                .map((item) => item?.replace(/"/g, ''))
                ?.includes(item?.key)
            ),
        isExcluded:
          queryParams
            ?.getAll(excludeKey)
            .map((item) => item?.replace(/"/g, ''))
            ?.includes(item?.key) ?? false,
        children,
      };
    });
    // Append Custom checkboxes from queryParams
    if (!NOT_ALLOWED_APPEND_CHECKBOXES?.includes(filterKey) && mainCheckbox) {
      // For Include
      const exceptListValues = getExceptFilterValues(
        queryParams.getAll(filterKey)?.map((item) => item?.replace(/"/g, '')),
        list,
      );
      if (exceptListValues?.length > 0) {
        exceptListValues?.map((value) => {
          initCheckboxes?.push({
            key: value,
            value,
            checked: true,
            isExcluded: false,
            children: [],
          });
        });
      }
      // For Exclude
      const exceptExcludeListValues = getExceptFilterValues(
        queryParams.getAll(excludeKey)?.map((item) => item?.replace(/"/g, '')),
        list,
      );
      if (exceptExcludeListValues?.length > 0) {
        exceptExcludeListValues?.map((value) => {
          initCheckboxes?.push({
            key: value,
            value,
            checked: true,
            isExcluded: false,
            children: [],
          });
        });
      }
    }
    // }
    return initCheckboxes;
  };

  const [inputValue, setInputValue] = useState('');
  // For handling the checboxes with url
  const [checkboxes, setCheckboxes] = useState(
    handleInitializeCheckboxes(true),
  );
  // For display only defalut checkboxes
  const [initialCheckboxes, setInitialCheckboxes] = useState(
    handleInitializeCheckboxes(false),
  );

  const [expanded, setExpanded] = useState([]);
  // For Input Autocomplete
  const [suggestions, setSuggestions] = useState([]);
  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const [showLabelToolTip, setShowLabelToolTip] = useState(null);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

  // For exact match option
  const [exactMatch, setExactMatch] = useState(null);

  // input/suggestion div ID
  const searchInputContainerId = `filter-${filterKey}-input`;
  const suggestionsContainerID = `autocomplete-${filterKey}`;
  const [showTagTooltip, setShowTagTooltip] = useState<any>(null);

  const handleGetCheckedKeys = (
    obj,
    include = true,
    showExactMatch?: boolean,
  ) => {
    let result = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (
        (include && obj[key]?.checked === true && !obj[key]?.isExcluded) ||
        (!include && obj[key]?.checked === true && obj[key]?.isExcluded)
      ) {
        result.push(obj[key]?.key);
        if (showExactMatch) {
          if (obj[key]?.children) {
            result = result?.concat(
              handleGetCheckedKeys(obj[key]?.children, true),
              handleGetCheckedKeys(obj[key]?.children, false),
            );
          }
        }
      } else if (obj[key]?.children && obj[key]?.checked === 'indeterminate') {
        // Include/exclude children only if the parent key status matches the desired status
        result = result?.concat(
          handleGetCheckedKeys(obj[key]?.children, include),
        );
      } else if (obj[key]?.checked === false) {
        // result.push(obj[key]?.children?.findOne(child => child?.checked === true)?.key)
        let data = obj[key]?.children?.filter(
          (child) => child?.checked === true,
        );
        if (data?.length) {
          result = result?.concat(handleGetCheckedKeys(data, include));
        }
      }
    }
    return result;
  };

  const handleCheckState = ({ checkedChild, totalChild }) => {
    let checked: string | boolean = false;
    if (checkedChild > 0 && checkedChild < totalChild) {
      checked = 'indeterminate';
    } else if (checkedChild === 0) {
      checked = false;
    } else {
      checked = true;
    }
    return checked;
  };

  const handlePreQuery = (updatedCheckboxes, showExactMatch?: boolean) => {
    const includedKeys = handleGetCheckedKeys(
      updatedCheckboxes,
      true,
      showExactMatch,
    );
    const excludedKeys = handleGetCheckedKeys(
      updatedCheckboxes,
      false,
      showExactMatch,
    );

    handleQuery({
      filterKey,
      filterExcludeKey: excludeKey,
      filterValue: { includedKeys, excludedKeys },
      filterType: FilterComponentType?.MULTILEVEL_CHECKBOX,
      showExactMatch,
    });
  };

  const handleOnChange = ({
    action = 'changeParent', // changeParent | changeChild | includeParent | includeChild | excludeParent | excludeChild | removeTag | inputOption
    groupName = null,
    childName = null,
    exclude = null,
  }: {
    action: string;
    groupName: string;
    childName?: string;
    exclude?: boolean;
  }) => {
    let groupIndex = -1;
    let childIndex = -1;
    if (groupName) {
      groupIndex = checkboxes?.findIndex((c) => c?.key === groupName);
    }
    if (
      childName &&
      groupIndex > -1 &&
      checkboxes[groupIndex] &&
      checkboxes[groupIndex]?.children?.length > 0
    ) {
      childIndex = checkboxes[groupIndex]?.children?.findIndex(
        (c) => c?.key === childName,
      );
    }
    setCheckboxes((prevState) => {
      let updatedValues = deepClone(prevState);
      // eslint-disable-next-line default-case

      // first time include and exclude
      const includeExcludeOptions = (exclude: boolean) => {
        let checkedCount = 0;
        const tagInputKey = getFilterKeys([groupName], list)[0] || groupName;
        if (tagInputKey) {
          groupIndex = checkboxes?.findIndex((c) => c?.key === tagInputKey);
        }
        updatedValues = updatedValues.map((item) => {
          let updatedItem = deepClone(item);
          if (groupIndex > -1 && item?.key === tagInputKey) {
            updatedItem.checked = true;
            checkedCount = 1;
            updatedItem.isExcluded = exclude;
          } else {
            groupName = findParentKey(list, tagInputKey);
            if (groupName) {
              groupIndex = checkboxes?.findIndex((c) => c?.key === groupName);
            }
            if (
              tagInputKey &&
              groupIndex > -1 &&
              checkboxes[groupIndex] &&
              checkboxes[groupIndex]?.children?.length > 0
            ) {
              childIndex = checkboxes[groupIndex]?.children?.findIndex(
                (c) => c?.key === tagInputKey,
              );
            }
            if (groupIndex > -1 && item?.key === groupName) {
              updatedItem = {
                ...updatedItem,
                children: updatedItem?.children?.map((child, index) => {
                  const childItem = deepClone(child);
                  if (index === childIndex) {
                    childItem.checked = true;
                    checkedCount = 1;
                    childItem.isExcluded = exclude;
                  }
                  return childItem;
                }),
              };
            }
          }
          return updatedItem;
        });
        // Append New Checkbox
        if (
          !NOT_ALLOWED_APPEND_CHECKBOXES?.includes(filterKey) &&
          checkedCount === 0
        ) {
          updatedValues.push({
            key: tagInputKey,
            value: tagInputKey,
            checked: true,
            isExcluded: exclude,
            children: [],
          });
        }
        setInputValue('');
      };

      switch (action) {
        case 'changeParent':
          if (groupIndex > -1) {
            updatedValues[groupIndex] = {
              ...updatedValues[groupIndex],
              checked: !prevState[groupIndex]?.checked,
              children: prevState[groupIndex]?.children?.map((child) => ({
                ...child,
                checked: !prevState[groupIndex]?.checked,
                isExcluded: false,
              })),
            };
          }
          break;
        case 'changeChild':
          if (groupIndex > -1 && childIndex > -1) {
            const children = prevState[
              groupIndex
            ]?.children?.map((child, cIndex) =>
              cIndex === childIndex
                ? { ...child, checked: !child?.checked }
                : child,
            );
            const checkedChild = children
              ?.map((c) => c?.checked)
              ?.filter(Boolean).length;
            updatedValues[groupIndex] = {
              ...updatedValues[groupIndex],
              checked: handleCheckState({
                checkedChild,
                totalChild: children?.length,
              }),
              children,
            };
          }
          break;
        case 'includeParent':
          if (groupIndex > -1) {
            updatedValues[groupIndex] = {
              ...updatedValues[groupIndex],
              checked: true,
              isExcluded: false,
              children: prevState[groupIndex]?.children?.map((child) => ({
                ...child,
                checked: true,
                isExcluded: false,
              })),
            };
          }
          break;
        case 'includeChild':
          if (groupIndex > -1 && childIndex > -1) {
            const children = prevState[
              groupIndex
            ]?.children?.map((child, cIndex) =>
              cIndex === childIndex
                ? { ...child, checked: true, isExcluded: false }
                : child,
            );
            const checkedChild = children
              ?.map((c) => c?.checked)
              ?.filter(Boolean)?.length;
            updatedValues[groupIndex] = {
              ...updatedValues[groupIndex],
              checked: handleCheckState({
                checkedChild,
                totalChild: children?.length,
              }),
              children,
            };
          }
          break;
        case 'excludeParent':
          if (groupIndex > -1) {
            updatedValues[groupIndex] = {
              ...updatedValues[groupIndex],
              checked: true,
              isExcluded: true,
              children: prevState[groupIndex]?.children?.map((child) => ({
                ...child,
                checked: true,
                isExcluded: true,
              })),
            };
          }
          break;
        case 'excludeChild':
          if (groupIndex > -1 && childIndex > -1) {
            const children = prevState[
              groupIndex
            ]?.children?.map((child, cIndex) =>
              cIndex === childIndex
                ? { ...child, checked: true, isExcluded: true }
                : child,
            );
            const checkedChild = children
              ?.map((c) => c?.checked)
              ?.filter(Boolean)?.length;
            updatedValues[groupIndex] = {
              ...updatedValues[groupIndex],
              checked: handleCheckState({
                checkedChild,
                totalChild: children?.length,
              }),
              children,
            };
          }
          break;
        case 'removeTag':
          // eslint-disable-next-line no-case-declarations
          // eslint-disable-next-line no-case-declarations
          let tagKey;
          tagKey = queryParams
            .getAll(groupName)
            ?.map((item) => item?.replace(/"/g, '').substring(0, 2));

          // Exact match
          if (tagKey?.length > 0) {
            // Remove Tag from Parent
            tagKey.map((tag) => {
              let tagIndex = checkboxes.findIndex((item) => {
                return item?.key?.substring(0, 2) === tag;
              });

              if (
                tagIndex > -1 &&
                updatedValues[tagIndex].checked !== 'indeterminate'
              ) {
                updatedValues[tagIndex] = {
                  ...updatedValues[tagIndex],
                  checked: false,
                  children: prevState[tagIndex]?.children?.map((child) => ({
                    ...child,
                    checked: false,
                  })),
                };
              } else {
                updatedValues[tagIndex] = {
                  ...updatedValues[tagIndex],
                  children: prevState[tagIndex]?.children?.map((child) => {
                    if (child.isExcluded === exclude) {
                      return {
                        ...child,
                        checked: false,
                      };
                    }
                    return child;
                  }),
                };
              }
            });
          } else {
            // for single tag remove
            const tagKey = getFilterKeys([groupName], list)[0] || groupName;
            if (tagKey.replace(/"/g, '')) {
              groupIndex = checkboxes?.findIndex(
                (c) => c?.key === tagKey?.replace(/"/g, ''),
              );
            }
            updatedValues[groupIndex] = {
              ...updatedValues[groupIndex],
              checked: false,
              children: prevState[groupIndex]?.children?.map((child) => ({
                ...child,
                checked: false,
              })),
            };
          }

          // for remove childeren tags
          if (groupIndex === -1) {
            const tagKey = getFilterKeys([groupName], list)[0] || groupName;
            groupName = findParentKey(list, tagKey);
            if (groupName) {
              groupIndex = checkboxes?.findIndex((c) => c?.key === groupName);
            }
            if (
              tagKey &&
              groupIndex > -1 &&
              checkboxes[groupIndex] &&
              checkboxes[groupIndex]?.children?.length > 0
            ) {
              childIndex = checkboxes[groupIndex]?.children?.findIndex(
                (c) => c?.key === tagKey,
              );
            }
            updatedValues[groupIndex] = {
              ...updatedValues[groupIndex],
              children: prevState[groupIndex]?.children?.map((child, cIndex) =>
                cIndex === childIndex
                  ? { ...child, checked: false, isExcluded: false }
                  : {
                      ...child,
                      isExcluded: child?.isExcluded,
                      checked: child?.checked,
                    },
              ),
            };
          }
          break;
        case 'inputOption':
          includeExcludeOptions(false);
          break;
        case 'excludeOption':
          includeExcludeOptions(true);
          break;
      }

      // append query
      handlePreQuery(updatedValues, exactMatch);

      return updatedValues;
    });
  };

  const onKeyDown = (e) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      const direction = e.key === 'ArrowDown' ? 1 : -1;
      const newIndex = selectedSuggestionIndex + direction;

      if (newIndex >= 0 && newIndex < suggestions?.length) {
        setSelectedSuggestionIndex(newIndex);
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (selectedSuggestionIndex !== -1) {
        const selectedSuggestion = suggestions[selectedSuggestionIndex];
        setInputValue(selectedSuggestion); // Update the input value with the selected suggestion
        setSuggestions([]); // Clear suggestions
        setShowAutoComplete(false);
        handleOnChange({
          action: 'inputOption',
          groupName: selectedSuggestion,
        });
        setSelectedSuggestionIndex(-1); // Reset selected suggestion index
      } else {
        const trimmedValue = e.target?.value.trim();
        if (trimmedValue === '') {
          return;
        }
        // Hide suggestions
        setShowAutoComplete(false);

        // Cancel the previous debounce
        debouncedInputChangeRef.current.cancel();

        handleOnChange({ action: 'inputOption', groupName: trimmedValue });
      }
    }
  };

  // For Input
  const handleInputChange = async (search: string) => {
    try {
      if (search.trim() !== '') {
        if (Object.keys(MAPPED_KEYS).includes(filterKey)) {
          const res: any = await getFilterSuggestionAPI({
            type: MAPPED_KEYS[filterKey],
            search,
          });
          const searchInputValue =
            (document?.getElementById(
              searchInputContainerId,
            ) as HTMLInputElement)?.value || inputValue;
          if (res && res?.payload && searchInputValue.trim() !== '') {
            setShowAutoComplete(true);
            setSuggestions(res?.payload);
            setSelectedSuggestionIndex(-1); // Reset selected suggestion index
          }
        }
      } else {
        setShowAutoComplete(false);
        setSelectedSuggestionIndex(-1); // Reset selected suggestion index
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error');
    }
  };

  // for handling show exact match
  const handleShowExactMatch = (checked: boolean) => {
    handlePreQuery(checkboxes, checked);
  };

  const debouncedInputChangeRef = useRef(debounce(handleInputChange, 500));

  // For Suggestion
  const onSelectOption = (option: string) => {
    setSuggestions([]);
    setShowAutoComplete(false);
    handleOnChange({ action: 'inputOption', groupName: option });
    setSelectedSuggestionIndex(-1); // Reset selected suggestion index
  };

  useFirstEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // eslint-disable-next-line no-restricted-globals
    const includedItemsFromParams = queryParams
      .getAll(filterKey)
      .map((item) => item.replace(/"/g, ''));
    const excludedItemsFromParams = queryParams
      .getAll(excludeKey)
      .map((item) => item.replace(/"/g, ''));

    if (
      includedItemsFromParams.length === 0 &&
      excludedItemsFromParams.length === 0
    ) {
      setExpanded([]);
    }

    setCheckboxes((prevState) => {
      let newState = [];
      if (prevState?.length > 0) {
        newState = prevState.map((item) => {
          let children = [];
          if (item?.children?.length > 0) {
            children = item?.children?.map((child) => ({
              ...child,
              isExcluded: excludedItemsFromParams.includes(child?.key) ?? false,
              checked:
                (includedItemsFromParams.includes(item?.key) ||
                  excludedItemsFromParams.includes(item?.key) ||
                  includedItemsFromParams.includes(child?.key) ||
                  excludedItemsFromParams.includes(child?.key)) ??
                false,
            }));
          }
          const checkedChild = children.map((c) => c?.checked).filter(Boolean)
            .length;
          let parentChecked = handleCheckState({
            checkedChild,
            totalChild: children.length,
          });
          if (
            includedItemsFromParams.includes(item?.key) ||
            excludedItemsFromParams.includes(item?.key)
          ) {
            parentChecked = true;
          }
          return {
            ...item,
            checked: parentChecked,
            children,
          };
        });
      }
      return newState;
    });
  }, [location?.search]);

  useEffect(() => {
    if (inputRef?.current && isExpand) {
      if (
        ['role_and_department', 'industry', 'education'].includes(parentKey)
      ) {
        if (['jobTitle', 'industry', 'major'].includes(filterKey)) {
          inputRef?.current.focus();
        }
      } else {
        inputRef?.current.focus();
      }
    }
  }, [isExpand]);

  useEffect(() => {
    if (typeof window !== 'undefined' && !hideSearchBox) {
      document.addEventListener('click', (event: any) => {
        const targetDiv = document.getElementById(suggestionsContainerID);
        // Check if the clicked element is outside the target div
        if (
          targetDiv &&
          event?.target !== targetDiv &&
          !targetDiv.contains(event?.target)
        ) {
          // The click is outside the target div
          setShowAutoComplete(false);
        }
      });
    }

    return () => {
      if (!hideSearchBox) {
        document.removeEventListener('click', () => {});
      }
      setShowLabelToolTip(null);
    };
  }, []);

  // for handling show exact match in referesh or location changes in url
  useEffect(() => {
    if (
      queryParams.getAll(filterKey)[0]?.includes(`"`) ||
      queryParams.getAll(excludeKey)[0]?.includes(`"`)
    ) {
      setExactMatch(true);
    } else {
      setExactMatch(false);
    }
  }, [location?.search]);

  return (
    <>
      <div
        className={`input-container ${inputClassName}`}
        id={suggestionsContainerID}
      >
        {inputLabel && <p className="blue-txt-15 label">{inputLabel}</p>}
        {!hideSearchBox && (
          <>
            <div className="position-relative">
              <input
                id={searchInputContainerId}
                ref={inputRef}
                type={
                  ['sicCode', 'naicsCode'].includes(filterKey) ? 'text' : 'text'
                }
                className="auto-search-input position-relative"
                autoComplete="off"
                onChange={(e) => {
                  // Cancel the previous debounce
                  debouncedInputChangeRef.current.cancel();

                  const enteredValue = e?.target?.value;

                  if (
                    ['sicCode', 'naicsCode'].includes(filterKey) &&
                    !/^\d*$/.test(enteredValue)
                  ) {
                    // If the filterKey is 'sicCode' or 'naicsCode' and the entered value contains non-digit characters, prevent setting the value
                    return;
                  }

                  // Trigger the new debounce
                  debouncedInputChangeRef.current(enteredValue);
                  setInputValue(enteredValue);
                }}
                onKeyDown={onKeyDown}
                value={inputValue}
                placeholder={inputPlaceHolder ?? 'Type and press Enter'}
              />

              {!['name', 'sicCode', 'naicsCode'].includes(filterKey) &&
                inputValue && (
                  <div className="include-exclude-icon position-absolute">
                    <OverlayTrigger
                      placement={Placement.Bottom}
                      overlay={
                        <Tooltip
                          id={`tooltip-${inputValue}-include-child-${inputValue}`}
                          className="bs-tooltip-inner"
                        >
                          Include
                        </Tooltip>
                      }
                    >
                      <CirclePlus
                        className="circle-plus include-circle-plus"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOnChange({
                            action: 'inputOption',
                            groupName: inputValue,
                          });
                          setShowAutoComplete(false);
                        }}
                      />
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement={Placement.Bottom}
                      overlay={
                        <Tooltip
                          id={`tooltip-${inputValue}-include-child-${inputValue}`}
                          className="bs-tooltip-inner"
                        >
                          Exclude
                        </Tooltip>
                      }
                    >
                      <CircleMinus
                        className="circle-minus"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOnChange({
                            action: 'excludeOption',
                            groupName: inputValue,
                          });
                          setShowAutoComplete(false);
                        }}
                      />
                    </OverlayTrigger>
                  </div>
                )}
            </div>
            {!['sicCode', 'naicsCode', 'department', 'industry'].includes(
              filterKey,
            ) && (
              <Checkbox
                value={filterKey}
                className="w-100 show-exact-match-check"
                onChange={(checked) => {
                  setExactMatch(checked);
                  handleShowExactMatch(checked);
                }}
                checked={exactMatch}
                label={
                  <div className="label-container d-inline-block mt-2">
                    <p className="checkbox-lable m-0">Show Exact Match</p>
                  </div>
                }
              />
            )}

            {showAutoComplete && suggestions?.length > 0 && (
              <RenderAutocomplete
                {...{
                  suggestions,
                  onSelectOption,
                  filterKey,
                  selectedSuggestionIndex,
                  handleOnChange,
                  setShowAutoComplete,
                }}
              />
            )}
          </>
        )}
      </div>
      <div className="d-flex flex-column">
        {!hideIncludeExclude &&
          getFilterValuesWithExtraValues(
            queryParams
              .getAll(filterKey)
              ?.map((item) => item?.replace(/"/g, '')),
            list,
          )?.length > 0 && (
            <>
              <p className="includes-excludes-text">Includes</p>
              <div>
                {exactMatch && filterKey === 'location' ? (
                  // for exact match tags
                  <span
                    className="w-100 d-block"
                    onMouseEnter={() => setShowTagTooltip(filterKey)}
                    onMouseLeave={() => setShowTagTooltip(null)}
                  >
                    <OverlayTooltip
                      placement={Placement.Bottom}
                      text={getFilterValuesWithExtraValues(
                        queryParams
                          .getAll(filterKey)
                          ?.map((item) => item?.replace(/"/g, '')),
                        list,
                      )?.join(', ')}
                      show={showTagTooltip === filterKey}
                    >
                      <span className="filter-container-lead d-flex align-items-center justify-content-between position-relative">
                        <span className="filter-tag-exact-match">
                          {getFilterValuesWithExtraValues(
                            queryParams
                              .getAll(filterKey)
                              ?.map((item) => item?.replace(/"/g, '')),
                            list,
                          )?.join(',')}
                        </span>
                        <Icon
                          identifier={`math-plus remove-icon`}
                          onClick={() =>
                            handleOnChange({
                              action: 'removeTag',
                              groupName: filterKey,
                              exclude: false,
                            })
                          }
                        />
                      </span>
                    </OverlayTooltip>
                  </span>
                ) : (
                  // for normal match tags
                  getFilterValuesWithExtraValues(
                    queryParams
                      .getAll(filterKey)
                      ?.map((item) => item?.replace(/"/g, '')),
                    list,
                  )?.map((selectedTag) => (
                    <span
                      className="d-inline-block"
                      onMouseEnter={() => setShowTagTooltip(selectedTag)}
                      onMouseLeave={() => setShowTagTooltip(null)}
                    >
                      <OverlayTrigger
                        placement={Placement.BottomStart}
                        overlay={getLebelTooltip(selectedTag)}
                        show={
                          showTagTooltip === selectedTag &&
                          selectedTag?.length > 15
                        }
                      >
                        <Tag
                          key={`tag-${selectedTag}`}
                          value={
                            selectedTag?.length > 15
                              ? `${selectedTag
                                  ?.substring(0, 15)
                                  .replace(/"/g, '')}..`
                              : selectedTag.replace(/"/g, '')
                          }
                          variant={Tag.Variant.Remove}
                          className="filter-tag "
                          onClick={() =>
                            handleOnChange({
                              action: 'removeTag',
                              groupName: selectedTag,
                            })
                          }
                        />
                      </OverlayTrigger>
                    </span>
                  ))
                )}
              </div>
            </>
          )}
        {!hideIncludeExclude &&
          getFilterValuesWithExtraValues(
            queryParams
              .getAll(excludeKey)
              ?.map((item) => item?.replace(/"/g, '')),
            list,
          )?.length > 0 && (
            <>
              <p className="includes-excludes-text">Excludes</p>
              <div>
                {exactMatch && filterKey === 'location' ? (
                  // for exact match tags
                  <span
                    className="d-block w-100 tag-container"
                    onMouseEnter={() => setShowTagTooltip(excludeKey)}
                    onMouseLeave={() => setShowTagTooltip(null)}
                  >
                    <OverlayTooltip
                      placement={Placement.Bottom}
                      text={getFilterValuesWithExtraValues(
                        queryParams
                          .getAll(excludeKey)
                          ?.map((item) => item?.replace(/"/g, '')),
                        list,
                      )?.join(',')}
                      show={showTagTooltip === excludeKey}
                    >
                      <span className="filter-container-lead d-flex align-items-center justify-content-between">
                        <span className="filter-tag-exact-match">
                          {getFilterValuesWithExtraValues(
                            queryParams
                              .getAll(excludeKey)
                              ?.map((item) => item?.replace(/"/g, '')),
                            list,
                          )?.join(',')}
                        </span>
                        <Icon
                          identifier={`math-plus remove-icon`}
                          onClick={() =>
                            handleOnChange({
                              action: 'removeTag',
                              groupName: excludeKey,
                              exclude: true,
                            })
                          }
                        />
                      </span>
                    </OverlayTooltip>
                  </span>
                ) : (
                  // for normal match tags
                  getFilterValuesWithExtraValues(
                    queryParams.getAll(excludeKey),
                    list,
                  )?.map((selectedTag) => (
                    <span
                      className="d-inline-block"
                      onMouseEnter={() => setShowTagTooltip(selectedTag)}
                      onMouseLeave={() => setShowTagTooltip(null)}
                    >
                      <OverlayTrigger
                        placement={Placement.BottomStart}
                        overlay={getLebelTooltip(selectedTag)}
                        show={
                          showTagTooltip === selectedTag &&
                          selectedTag?.length > 15
                        }
                      >
                        <Tag
                          key={`tag-${selectedTag}`}
                          value={
                            selectedTag?.length > 15
                              ? `${selectedTag
                                  ?.substring(0, 15)
                                  .replace(/"/g, '')}..`
                              : selectedTag.replace(/"/g, '')
                          }
                          variant={Tag.Variant.Remove}
                          className="filter-tag"
                          onClick={() =>
                            handleOnChange({
                              action: 'removeTag',
                              groupName: selectedTag,
                            })
                          }
                        />
                      </OverlayTrigger>
                    </span>
                  ))
                )}
              </div>
            </>
          )}
        {checkboxes?.length > 0 && (
          <div className={removeTopMargin ? '' : 'filter-checkbox-container'}>
            {checkboxes?.map(
              (item, index) =>
                item?.key === initialCheckboxes[index]?.key && (
                  <div className="filter-checkbox" key={`item-${item.key}`}>
                    <div className="expandedIcon-checkbox">
                      <div className="minus-plus-icon">
                        {item?.children?.length > 0 && (
                          <>
                            {expanded.includes(item.key) ? (
                              <Minus
                                onClick={() =>
                                  setExpanded((d) =>
                                    d.filter((i) => i !== item.key),
                                  )
                                }
                              />
                            ) : (
                              <Plus
                                onClick={() =>
                                  setExpanded((d) => [...d, item.key])
                                }
                              />
                            )}
                          </>
                        )}
                      </div>
                      <div
                        className={`w-100 ${
                          item?.children?.length === 0 ? 'render-checkbox' : ''
                        }`}
                      >
                        <RenderCheckboxGroup
                          name={item.key}
                          label={item?.value}
                          checked={item.checked}
                          // eslint-disable-next-line react/no-children-prop
                          children={item.children}
                          expanded={expanded}
                          handleOnChange={handleOnChange}
                          {...item}
                          filterKey={filterKey}
                          showLabelToolTip={showLabelToolTip}
                          setShowLabelToolTip={setShowLabelToolTip}
                          infoCircleToolTip={
                            infoCircleToolTipMapping?.[item?.key]
                          }
                          hideIncludeExclude={hideIncludeExclude}
                        />
                      </div>
                    </div>
                  </div>
                ),
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default FilterCheckbox;
