/* eslint-disable no-underscore-dangle */
class PlanErrorHelper {
  private _shoeUpdatePlanModal;

  bindActions = (showUpdatePlanModal) => {
    this._shoeUpdatePlanModal = showUpdatePlanModal;
  };

  showModal = (title: string, contents: string[], showButtons: boolean) =>
    this._shoeUpdatePlanModal(title, contents, showButtons);
}

export default PlanErrorHelper;
