export enum EmailAccountMethod {
  Gmail = 'gmail',
  Microsoft = 'microsoft',
  SmtpImap = 'smtp-imap',
}

export enum EmailAccountType {
  Gmail = 1,
  Microsoft = 2,
  SmtpImap = 3,
}

export enum EmailServiceProvider {
  Gmail = 'gmail',
  Gsuite = 'gsuite',
  Microsoft = 'microsoft',
  O365 = 'o365',
  Yahoo = 'yahoo',
  Zoho = 'zoho',
  Godaddy = 'godaddy',
  Yandex = 'yandex',
  Sendgrid = 'sendgrid',
  Other = 'other',
}

export enum EmailAccountsSortBy {
  SetupScore = 'health-score',
  EmailSent = 'remaining-quota',
  ClientAssociated = 'clientFirstName',
  DeliverabilityRate = 'deliverabilityRate',
}
