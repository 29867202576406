/* eslint-disable no-nested-ternary */
import React from 'react';
import Form from '../../../../../../../../shared/design-system/components/organisms/form';
import { RequestStatus } from '../../../../../../../../shared/enums/request-status';
import {
  FormField,
  FormMode,
  InputValidationType,
} from '../../../../../../../../shared/design-system/components/organisms/form/types';
import Spinner, {
  SpinnerAnimation,
} from '../../../../../../../../shared/design-system/components/atoms/spinner/spinner';
import { SelectedProspectData } from '../../../../../../../sequence/types';
import { executeOnRequestStatusWithPrevStatusCheck } from '../../../../../../../../shared/utils';
import toaster from '../../../../../../../../shared/toaster';

type IProps = {
  selectedProspectData: SelectedProspectData;
  selectedProspectId: number;
  onClose: () => void;
  handleSaveContactAttributesSubmit: (attributes) => void;
  getSingleProspectDetailsStatus: RequestStatus;
  saveContactAttributesRequestStatus: RequestStatus;
  sendGetSingleProspectDetailsRequest: (contactId: number) => void;
  sendPrimaryEmailChangeInProspectRequest: (
    primaryEmailProspectInfoId: number,
    contactId: number,
  ) => void;
  sendDeleteEmailFromProspectRequest: (
    prospectInfoId: number,
    contactId: number,
  ) => void;
  sendDeletePhoneFromProspectRequest: (
    prospectInfoId: number,
    contactId: number,
  ) => void;
  deleteSecondaryEmailRequestStatus?: string;
  resetProspectDeleteEmailRequestStatus?: () => void;
  deleteSecondaryPhoneRequestStatus?: string;
  resetProspectDeletePhoneRequestStatus?: () => void;
  resetProspectPrimaryEmailChangeRequestStatus?: () => void;
  primaryEmailChangeRequestStatus: string;
  onActivityChange: (value: boolean) => void;
};

class ProspectProfileForm extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.prepareFormConfig = this.prepareFormConfig.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const {
      deleteSecondaryEmailRequestStatus,
      resetProspectDeleteEmailRequestStatus,
      sendGetSingleProspectDetailsRequest,
      selectedProspectId,
      deleteSecondaryPhoneRequestStatus,
      resetProspectDeletePhoneRequestStatus,
      resetProspectPrimaryEmailChangeRequestStatus,
      primaryEmailChangeRequestStatus,
      onActivityChange,
    } = this.props;
    executeOnRequestStatusWithPrevStatusCheck({
      status: deleteSecondaryEmailRequestStatus,
      prevStatus: prevProps?.deleteSecondaryEmailRequestStatus,
      onSuccess: () => {
        toaster.success('Email address deleted successfully');
        resetProspectDeleteEmailRequestStatus();
        sendGetSingleProspectDetailsRequest(selectedProspectId);
      },
    });
    executeOnRequestStatusWithPrevStatusCheck({
      status: deleteSecondaryPhoneRequestStatus,
      prevStatus: prevProps?.deleteSecondaryPhoneRequestStatus,
      onSuccess: () => {
        toaster.success('Phone number deleted successfully');
        resetProspectDeletePhoneRequestStatus();
        sendGetSingleProspectDetailsRequest(selectedProspectId);
      },
    });
    executeOnRequestStatusWithPrevStatusCheck({
      status: primaryEmailChangeRequestStatus,
      prevStatus: prevProps?.primaryEmailChangeRequestStatus,
      onSuccess: () => {
        toaster.success('Primary Email address updated successfully');
        resetProspectPrimaryEmailChangeRequestStatus();
        sendGetSingleProspectDetailsRequest(selectedProspectId);
        onActivityChange(true);
      },
    });
  }

  getShouldFormFieldDisabled = (name: string): boolean =>
    name === 'email' || name === 'companydomain' || name === 'creationsource';

  prepareFormConfig(): FormField[] {
    const { selectedProspectData } = this.props;

    const { attributes } = selectedProspectData || { attributes: [] };
    return attributes.map((attribute: any) => {
      const name = attribute.fieldRef?.label?.replace(' ', '').toLowerCase();
      return {
        id: attribute.fieldRef.id,
        isDefault: attribute.fieldRef.isDefault,
        name,
        disabled: this.getShouldFormFieldDisabled(name),
        displayName: attribute.fieldRef.label,
        component: attribute.fieldRef.fieldType,
        value: attribute.attributeValue,
        styleClassNames: 'col-md-6',

        validator:
          name === 'email'
            ? InputValidationType.isEmail
            : name === 'phonenumber'
            ? InputValidationType.isMobilePhone
            : InputValidationType.MaxCharLimit,
      };
    });
  }

  handleSubmit(values) {
    const {
      selectedProspectData,
      handleSaveContactAttributesSubmit,
    } = this.props;

    const { attributes } = selectedProspectData || { attributes: [] };

    const payload = attributes.map((attribute: any) => {
      const key = attribute.fieldRef?.label?.replace(' ', '').toLowerCase();
      return { attributeValue: values[key], fieldRef: attribute.fieldRef.id };
    });
    handleSaveContactAttributesSubmit(payload);
  }

  render() {
    const {
      getSingleProspectDetailsStatus,
      onClose,
      saveContactAttributesRequestStatus,
      selectedProspectData,
      sendPrimaryEmailChangeInProspectRequest,
      sendDeleteEmailFromProspectRequest,
      sendDeletePhoneFromProspectRequest,
    } = this.props;

    if (
      !selectedProspectData ||
      getSingleProspectDetailsStatus === RequestStatus.Pending ||
      getSingleProspectDetailsStatus === RequestStatus.Failed
    ) {
      return (
        <div className="mt-5 d-flex justify-content-center">
          <Spinner animation={SpinnerAnimation.Border} />
        </div>
      );
    }
    return (
      <Form
        fields={this.prepareFormConfig()}
        formMode={FormMode.Edit}
        formBodyClasses="form-body prospect-modal-user-field"
        formContainerClasses="mr-3"
        onSubmit={this.handleSubmit}
        buttonClassName="mr-5"
        onCancel={onClose}
        submitStatus={
          saveContactAttributesRequestStatus === RequestStatus.Pending
        }
        selectedProspectId={selectedProspectData?.id}
        primaryEmailChangeInProspectRequest={
          sendPrimaryEmailChangeInProspectRequest
        }
        deleteEmailFromProspectRequest={sendDeleteEmailFromProspectRequest}
        deletePhoneFromProspectRequest={sendDeletePhoneFromProspectRequest}
      />
    );
  }
}

export default ProspectProfileForm;
