import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../../../store/root-reducer';
import { AddTagsToImportedContactsPayload } from '../../../../../../types';
import { FileData } from '../select-file/types';
import EmailVerification from './email-verification';
import { getTagsRequest } from '../../../../../../../prospect/extra-actions';
import { getEmailVerificationCreditsRequest } from '../../../../../../../settings/components/billing-subscription/extra-actions';

const mapStateToProps = (state: RootState) => ({
  tags: state.prospect.tags,
  sendGetEmailVerificationCreditsRequestStatus:
    state.billingAndSubscription.getEmailVerificationCreditsRequest.status,
  getEmailVerificationCreditsResponse:
    state.billingAndSubscription.getEmailVerificationCreditsResponse,
});

const mapDispatchToProps = {
  sendGetTagsRequest: () => getTagsRequest(),
  sendGetEmailVerificationCreditsRequest: () =>
    getEmailVerificationCreditsRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  onClick: (
    includeRiskyContact: boolean,
    includeVerification: boolean,
    tags?: AddTagsToImportedContactsPayload,
  ) => void;
  isRequestPending: boolean;
  fileData: FileData;
  onBuyNow: (remainingCredit: number) => void;
  isPurchaseEmailVerificationCreditsLoading: boolean;
  warning: string;
  isEmailVerification: boolean;
  handleOnFinishWithoutVerification: (
    tags?: AddTagsToImportedContactsPayload,
  ) => void;
};

export default connector(EmailVerification);
