import { useRef, useEffect } from 'react';

export function useFilteredEffect(filters, callback) {
  const previousFilters = useRef(filters);

  useEffect(() => {
    const { page, ...current } = filters;
    const { page: prevPage, ...previous } = previousFilters.current;

    // Compare current and previous, excluding page and sort
    if (JSON.stringify(current) !== JSON.stringify(previous)) {
      callback();
    }

    previousFilters.current = filters;
  }, [filters, callback]);
}
