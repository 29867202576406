import { withTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { IProps, FieldContentTabs } from './types';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import { PaginationQueryParams } from '../../../../../../shared/types/request';
import CustomFieldTabPanes from './custom-field-tabpanes';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import { GlobalSettings } from '../../../../../../shared/components/global-settings-wrapper';
import { ResourceIdentifiers } from '../../../../../../shared/utils/access-control/enums/resource-identifiers';
import hasResource from '../../../../../../shared/utils/access-control/has-resource';

const CustomFieldContent: React.FC<IProps> = ({
  customFields,
  defaultFields,
  customFieldPagination,
  defaultFieldPagination,
  sendGetCustomFieldsRequest,
  sendGetDefaultFieldsRequest,
  onUpdateHandler,
  onDeleteHandler,
  onCreateHandler,
  isRequestPending,
  subscriptionPlan,
  t,
  activeTabKey,
  setActiveTabKey,
}) => {
  const onTabChange = (tabKey) => {
    setActiveTabKey(tabKey);
  };

  const onCustomFieldPaginationOptionsChangeHandler = (
    newPartialOptions: Partial<PaginationQueryParams>,
  ) => {
    const { options } = customFieldPagination;
    const payload = { ...options, ...newPartialOptions };
    delete payload.totalElements;
    sendGetCustomFieldsRequest(payload);
  };

  const onDefaultFieldPaginationOptionsChangeHandler = (
    newPartialOptions: Partial<PaginationQueryParams>,
  ) => {
    const { options } = customFieldPagination;
    const payload = { ...options, ...newPartialOptions };
    delete payload.totalElements;
    sendGetDefaultFieldsRequest(payload);
  };

  const onAction = (key, row) =>
    key === 'edit' ? onUpdateHandler(row) : onDeleteHandler(row);

  return (
    <GlobalSettings.Content className="custom-fields-content-container">
      {activeTabKey === FieldContentTabs.CustomFields &&
        hasPermission(Permissions.FIELD_WRITE) && (
          <div className="add-custom-fields-container">
            <Button
              onClick={onCreateHandler}
              variant={Button.Variant.Primary}
              disabled={!hasResource(ResourceIdentifiers.FIELDS_CREATE)}
              className="header-btn"
            >
              {t('labels.add_prospect_fields')}
            </Button>
          </div>
        )}
      <CustomFieldTabPanes
        onCreateHandler={onCreateHandler}
        customFields={customFields}
        defaultFields={defaultFields}
        defaultFieldPagination={defaultFieldPagination}
        customFieldPagination={customFieldPagination}
        onActonHandler={onAction}
        onCustomFieldPaginationOptionsChangeHandler={
          onCustomFieldPaginationOptionsChangeHandler
        }
        onDefaultFieldPaginationOptionsChangeHandler={
          onDefaultFieldPaginationOptionsChangeHandler
        }
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        subscriptionPlan={subscriptionPlan}
        isRequestPending={isRequestPending}
      />
    </GlobalSettings.Content>
  );
};

export default withTranslation()(CustomFieldContent);
