import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionPlans } from '../../../../../shared/utils/subscription-plans';
import { Images } from '../../../../../shared/app-constants';
import {
  getRestrictionWarning,
  getRestrictionWarningTooltip,
  getTrailPlanWarningString,
} from '../utils/helper';
import { OverlayTooltip } from '../../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../../shared/utils/accessible-on-click';
import { getUpgradePlanCTA } from '../../../../../shared/utils/get-upgrade-cta';
import hasPermission from '../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../shared/utils/access-control/enums/permissions';
import { isPlanDowngradedFromTrial } from '../../../utils/plan-transition';
import store from '../../../../../store';
import {
  isAgencificationClientOrAgencyUser,
  isAgency,
} from '../../../../../shared/utils/user-details';

type BannerMetaData = {
  show: boolean;
  type?: 'warning' | 'danger';
  imgSrc?: string;
  message?: string;
  showTooltip?: boolean;
  tooltipText?: string;
  isPostLoadMetaRequestPending?: boolean;
};

const HeaderBanner = ({
  subscriptionPlan,
  subscriptionEndDays,
  pathname,
  leadFinderCredits,
  handleUpgradePlanCTA,
  isPostLoadMetaRequestPending,
}) => {
  const { t } = useTranslation();
  const state = store.getState();
  const planTransitionDetails = state.home.subscription?.planTransitionDetails;

  const getBannerMetaData = (): BannerMetaData => {
    const path = pathname.split('/')?.[1];
    if (isAgencificationClientOrAgencyUser()) {
      return {
        show: false,
        type: 'warning',
        imgSrc: '',
        message: '',
        showTooltip: false,
        tooltipText: '',
      };
    }

    if (
      !isPostLoadMetaRequestPending &&
      path === 'leads' &&
      leadFinderCredits < 100 &&
      hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
    ) {
      return {
        show: true,
        type: 'warning',
        imgSrc: Images.AlertTriangle,
        message: 'Your lead finder credits are low.',
        showTooltip: false,
        tooltipText: '',
      };
    }

    if (subscriptionPlan === SubscriptionPlans.TRIAL) {
      return {
        show: true,
        type: 'warning',
        imgSrc: Images.AlertTriangle,
        message: getTrailPlanWarningString({ t, subscriptionEndDays }),
        showTooltip: false,
        tooltipText: '',
      };
    }

    if (subscriptionPlan === SubscriptionPlans.FREE) {
      if (isPlanDowngradedFromTrial(planTransitionDetails)) {
        return {
          show: true,
          type: 'danger',
          imgSrc: Images.AlertTriangleRed,
          message: 'Your trial period is expired.',
          showTooltip: false,
          tooltipText: '',
        };
      }
      return {
        show: true,
        type: 'danger',
        imgSrc: Images.AlertTriangleRed,
        message: 'Your plan has expired.',
        showTooltip: false,
        tooltipText: '',
      };
    }

    const warningMessage = getRestrictionWarning();
    if (warningMessage) {
      return {
        show: true,
        type: 'danger',
        imgSrc: Images.AlertTriangleRed,
        message: warningMessage,
        showTooltip: true,
        tooltipText: getRestrictionWarningTooltip(),
      };
    }

    return {
      show: false,
      type: 'warning',
      imgSrc: '',
      message: '',
      showTooltip: false,
      tooltipText: '',
    };
  };

  const {
    show,
    type,
    imgSrc,
    message,
    showTooltip,
    tooltipText,
  } = getBannerMetaData();

  const bannerMsgJSX = (
    <span className="regular-1 font-medium popover-arrow-color-txt ml-1">
      {message}{' '}
    </span>
  );

  return (
    show && (
      <div className={`bs-banner mr-2 bs-banner-${type}`}>
        <img src={imgSrc} alt={type} />

        {showTooltip ? (
          <OverlayTooltip text={tooltipText}>{bannerMsgJSX}</OverlayTooltip>
        ) : (
          bannerMsgJSX
        )}

        <span
          role="button"
          className="bs-banner__cta regular-1 font-medium popover-arrow-color-txt"
          {...accessibleOnClick(() => handleUpgradePlanCTA())}
        >
          {t(`labels.${getUpgradePlanCTA()}`)}
        </span>
      </div>
    )
  );
};

export default HeaderBanner;
