import React from 'react';
import Avatar from '../../initials-avatar';

export function AvatarEmail({ userProfile }) {
  return (
    <li className="user-profile-dropdown-row">
        <div className="user-dropdown-option">
        <div className="user-initials-avatar">
          <Avatar
            firstName={userProfile.firstName}
            lastName={userProfile.lastName}
            className='user-avatar-dropdown'
          />
        </div>
        <div className="user-label-dropdown">
          <p className="user-label-fullname" >
            {userProfile.firstName} {userProfile.lastName}
          </p>
          <p className="user-label-email">{userProfile.email}</p>
        </div>
      </div>
    </li>
  );
}
