import React from 'react';
import { IProps } from './types';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import { GlobalSettings } from '../../../../../../shared/components/global-settings-wrapper';
import hasResource from '../../../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../../../shared/utils/access-control/enums/resource-identifiers';

const ScheduleHeader: React.FC<IProps> = ({
  headerName,
  buttonText,
  clickHandler,
  subscriptionPlan,
}) => (
  <GlobalSettings.Header>
    <GlobalSettings.HeaderLeft>
      <GlobalSettings.HeaderTitle title={headerName} />
    </GlobalSettings.HeaderLeft>
    <GlobalSettings.HeaderRight>
      {/* TODO: refactor subscription plan check condition */}
      {hasPermission(Permissions.SCHEDULE_WRITE) && (
        <Button
          variant={Button.Variant.Primary}
          onClick={clickHandler}
          disabled={!hasResource(ResourceIdentifiers.SCHEDULES_CREATE)}
          className="header-btn"
        >
          {buttonText}
        </Button>
      )}
    </GlobalSettings.HeaderRight>
  </GlobalSettings.Header>
);

export default ScheduleHeader;
